<template>
  <div style="height: calc(100% - 63px)">
    <el-row class="sale_content">
      <el-col :span="9" class="project_left">
        <div class="pad_15">
          <el-input placeholder="请输入商品名称、别名关键字，按回车搜索" prefix-icon="el-icon-search" v-model="goodsName" clearable size="small" @keyup.enter.native="searchGoodsClick" @clear="clearClick">
            <el-select v-model="typeIndex" slot="prepend" style="width: 100px">
              <el-option label="全部" value="0"></el-option>
              <el-option label="项目" value="1"></el-option>
              <el-option label="储值卡" value="2"></el-option>
              <el-option label="时效卡" value="3"></el-option>
              <el-option label="通用次卡" value="4"></el-option>
              <el-option label="套餐卡" value="5"></el-option>
              <el-option label="产品" value="6"></el-option>
            </el-select>
          </el-input>
        </div>
        <el-tabs v-model="tabPane">
          <el-tab-pane label="全部" name="0">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div :class="[goodsType == 'Project' ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="goodsCategoryChange('Project')" v-if="goodsList.Project != undefined && goodsList.Project.length > 0">
                    项目
                  </div>
                  <div :class="[goodsType == 'SavingCard' ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="goodsCategoryChange('SavingCard')" v-if="goodsList.SavingCard != undefined && goodsList.SavingCard.length > 0">
                    储值卡
                  </div>
                  <div :class="[goodsType == 'TimeCard' ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="goodsCategoryChange('TimeCard')" v-if="goodsList.TimeCard != undefined && goodsList.TimeCard.length > 0">
                    时效卡
                  </div>
                  <div :class="[goodsType == 'GeneralCard' ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="goodsCategoryChange('GeneralCard')" v-if="goodsList.GeneralCard != undefined && goodsList.GeneralCard.length > 0">
                    通用次卡
                  </div>
                  <div :class="[goodsType == 'PackageCard' ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="goodsCategoryChange('PackageCard')" v-if="goodsList.PackageCard != undefined && goodsList.PackageCard.length > 0">
                    套餐卡
                  </div>
                  <div :class="[goodsType == 'Product' ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="goodsCategoryChange('Product')" v-if="goodsList.Product != undefined && goodsList.Product.length > 0">
                    产品
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <div v-for="product in goodsAll" :key="product.ID" class="border_bottom pad_5_10 cursor_pointer" @click="goodsChange(product)">
                      <div>
                        <span>{{ product.Name }}</span>
                        <span v-if="product.Alias">({{ product.Alias }})</span>
                        <span v-if="goodsType == 'GeneralCard'" class="font_12 color_gray">× {{ product.Amount }}次</span>
                      </div>
                      <div>
                        <span class="color_red">¥ {{ product.Price | NumFormat }}</span>
                        <span class="marlt_10 font_12 color_gray" v-if="product.LargessPrice">赠送：¥ {{ product.LargessPrice | NumFormat }}</span>
                        <span class="font_12 color_gray" style="float: right">{{ product.ValidDayName }}</span>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="项目" v-if="IsExistProject" name="1">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div v-for="(item, index) in projectCategory" :key="index" :class="[projectCategoryIndex == index ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="projectCategoryChange(item, index)">
                    {{ item.Name }}
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <!--<el-menu :default-active="categorySubIndex" mode="horizontal"-->
                <!--@select="categorySubChange">-->
                <!--<el-scrollbar class="el-scrollbar_x_width">-->
                <!--<el-menu-item :index="`${index}`"-->
                <!--v-for="(item,index) in projectList"-->
                <!--:key="index">-->
                <!--<a href="javascript:void(0)" @click="navChange(index,`#inclode${index}`)">{{item.Name}}</a>-->
                <!--</el-menu-item>-->
                <!--</el-scrollbar>-->
                <!--</el-menu>-->

                <div class="project_list">
                  <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
                    <!-- <el-collapse v-model="collapseName"> -->
                    <el-collapse accordion>
                      <el-collapse-item v-for="(item, index) in projectList" :key="index" :title="item.Name" :id="`inclode${index}`" :name="item.ID">
                        <div v-for="project in item.Project" class="border_bottom pad_5_10 cursor_pointer" :key="project.ID" @click="projectChange(project)">
                          <div>
                            <span>{{ project.Name }}</span>
                            <span v-if="project.Alias">({{ project.Alias }})</span>
                          </div>
                          <div class="color_red">¥ {{ project.Price | NumFormat }}</div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="储值卡" v-if="IsExistSavingCard" name="2">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div v-for="(item, index) in savingCardCategory" :key="index" :class="[savingCategoryIndex == index ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="savingCategoryChange(item, index)">
                    {{ item.Name }}
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <div v-for="saving in savingCardList" :key="saving.ID" class="border_bottom pad_5_10 cursor_pointer" @click="savingCardChange(saving)">
                      <div>
                        <span>{{ saving.Name }}</span>
                        <span v-if="saving.Alias">({{ saving.Alias }})</span>
                      </div>
                      <div>
                        <span class="color_red">¥ {{ saving.Price | NumFormat }}</span>
                        <span class="marlt_10 font_12 color_gray" v-if="saving.LargessPrice">赠送：¥ {{ saving.LargessPrice }}</span>
                        <span class="font_12 color_gray" style="float: right">{{ saving.ValidDayName }}</span>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="时效卡" v-if="IsExistTimeCard" name="3">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div v-for="(item, index) in timeCardCategory" :key="index" :class="[timeCategoryIndex == index ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="timeCategoryChange(item, index)">
                    {{ item.Name }}
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <div v-for="time in timeCardList" :key="time.ID" class="border_bottom pad_5_10 cursor_pointer" @click="timeCardChange(time)">
                      <div>
                        <span>{{ time.Name }}</span>
                        <span v-if="time.Alias">({{ time.Alias }})</span>
                      </div>
                      <div class="color_red">
                        <span>¥ {{ time.Price | NumFormat }}</span>
                        <span class="font_12 color_gray" style="float: right">{{ time.ValidDayName }}</span>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="通用次卡" v-if="IsExistGeneralCard" name="4">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div v-for="(item, index) in generalCardCategory" :key="index" :class="[generalCategoryIndex == index ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="generalCategoryChange(item, index)">
                    {{ item.Name }}
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <div v-for="general in generalCardList" :key="general.ID" class="border_bottom pad_5_10 cursor_pointer" @click="generalCardChange(general)">
                      <div>
                        <span>{{ general.Name }}</span>
                        <span v-if="general.Alias">({{ general.Alias }})</span>
                        <span class="font_12 color_gray">× {{ general.Amount }}次</span>
                      </div>
                      <div class="color_red">
                        <span>¥ {{ general.Price | NumFormat }}</span>
                        <span class="font_12 color_gray" style="float: right">{{ general.ValidDayName }}</span>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="套餐卡" v-if="IsExistPackageCard" name="5">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div v-for="(item, index) in packageCardCategory" :key="index" :class="[packageCategoryIndex == index ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="packageCategoryChange(item, index)">
                    {{ item.Name }}
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <div v-for="pack in packageCardList" :key="pack.ID" class="border_bottom pad_5_10 cursor_pointer" @click="packageCardChange(pack)">
                      <div>
                        <span>{{ pack.Name }}</span>
                        <span v-if="pack.Alias">({{ pack.Alias }})</span>
                      </div>
                      <div class="color_red">
                        <span>¥ {{ pack.Price | NumFormat }}</span>
                        <span class="font_12 color_gray" style="float: right">{{ pack.ValidDayName }}</span>
                      </div>
                    </div>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="产品" v-if="IsExistProduct" name="6">
            <el-row class="category_project">
              <el-col :span="6" class="border_right text_center category">
                <el-scrollbar class="el-scrollbar_height">
                  <div v-for="(item, index) in productCategory" :key="index" :class="[productCategoryIndex == index ? 'category_select' : '', 'pad_10_0', 'border_bottom', 'cursor_pointer']" @click="productCategoryChange(item, index)">
                    {{ item.Name }}
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col :span="18" class="project">
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <!-- <el-collapse v-model="productCollapseName"> -->
                    <el-collapse accordion>
                      <el-collapse-item v-for="(item, index) in productList" :key="index" :title="item.Name" :id="`inclode${index}`" :name="item.ID">
                        <div v-for="product in item.Product" class="border_bottom pad_5_10 cursor_pointer" :key="product.ID" @click="productChange(product)">
                          <div>
                            <span>{{ product.Name }}</span>
                            <span v-if="product.Alias">({{ product.Alias }})</span>
                          </div>
                          <div class="color_red">¥ {{ product.Price | NumFormat }}</div>
                        </div>
                      </el-collapse-item>
                    </el-collapse>

                    <!-- <div v-for="product in productList" :key="product.ID" class="border_bottom pad_5_10 cursor_pointer" @click="productChange(product)">
                      <div>
                        <span>{{product.Name}}</span>
                        <span v-if="product.Alias">({{product.Alias}})</span>
                      </div>
                      <div class="color_red">¥ {{product.Price | NumFormat}}</div>
                    </div> -->
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="15" class="project_right">
        <el-container style="height: 100%">
          <el-main>
            <el-scrollbar class="el-scrollbar_height">
              <!--项目-->
              <div v-if="selectProject.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">项目</el-col>
                    <el-col :span="5">数量</el-col>
                    <el-col :span="5">金额</el-col>
                    <el-col :span="5">欠款</el-col>
                    <el-col :span="2">赠送</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item, index) in selectProject" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="6">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <span class="marlt_10 color_primary cursor_pointer">
                            <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="editProjectRemarkClick(item)"></el-button>
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="5">
                        <el-input-number :min="1" size="mini" style="width: 100px" v-model="item.number" @change="numberChange(item)"></el-input-number>
                      </el-col>
                      <el-col :span="5">
                        <span class="marrt_10">{{ parseFloat(item.TotalAmount) | NumFormat }}</span>
                        <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="savingCardDeductionClick(1, selectProject, item, index)" v-if="!item.IsLargess"></el-button>
                      </el-col>
                      <el-col :span="5">
                        <el-input v-model="item.ArrearAmount" :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 70px" @input="arrearChange(item)" :disabled="item.IsLargess"></el-input>
                      </el-col>
                      <el-col :span="2">
                        <el-checkbox v-show="item.IsAllowLargess" v-model="item.IsLargess" @change="largessChange(item)"></el-checkbox>
                      </el-col>
                      <el-col :span="1" :offset="item.IsAllowLargess ? 0 : 2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(1, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="4">
                        <div class="color_red">¥ {{ item.Price | NumFormat }}</div>
                      </el-col>
                      <el-col :span="20">
                        <div class="text_right">
                          <span class="font_12">支付金额：{{ (item.IsLargess ? 0 : item.PayAmount) | NumFormat }}</span>
                          <span class="color_gray font_12 marlt_15" v-if="item.discountPrice != 0">
                            手动改价：
                            <span class="color_red" v-if="item.discountPrice > 0">-{{ item.discountPrice | NumFormat }}</span>
                            <span class="color_green" v-else>+{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDiscountPrice > 0">
                            卡优惠：
                            <span class="color_red">-{{ parseFloat(item.CardDiscountPrice) | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDeductionAmount > 0">
                            卡抵扣：
                            <span class="color_red">-{{ parseFloat(item.CardDeductionAmount) | NumFormat }}</span>
                          </span>
                        </div>
                      </el-col>
                    </el-col>
                  </el-col>

                  <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息: {{ item.Remark }} </el-col>

                  <el-col v-if="item.handleTypeList.length" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="employeeHandleClick(1, selectProject, item, index)" type="flex" align="top">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee, employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handler, handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--储值卡-->
              <div v-if="selectSavingCard.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">储值卡</el-col>
                    <el-col :span="5">数量</el-col>
                    <el-col :span="8">金额/赠额</el-col>
                    <el-col :span="4">欠款</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item, index) in selectSavingCard" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="6">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <span class="marlt_10 color_primary cursor_pointer">
                            <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="editProjectRemarkClick(item)"></el-button>
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="5">
                        <el-input-number :min="1" size="mini" style="width: 100px" v-model="item.number" @change="numberChange(item)"></el-input-number>
                      </el-col>
                      <el-col :span="8">
                        <el-row>
                          <el-col :span="7">
                            <el-input v-model="item.Amount" placeholder="金额" :min="0" class="savingCardAmount" type="number" v-enter-number2 size="mini" @input="savingAmountChange(item)" :disabled="!item.IsModifyPrice"></el-input>
                          </el-col>
                          <el-col :span="7">
                            <el-input v-model="item.LargessPrice" placeholder="赠送金额" :min="0" class="savingCardLargessAmount" type="number" v-enter-number2 size="mini" :disabled="!item.IsModifyLargessPrice"></el-input>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="4">
                        <el-row>
                          <el-col :span="18">
                            <el-input v-model="item.ArrearAmount" placeholder :min="0" type="number" v-enter-number2 size="mini" @input="arrearChange(item)"></el-input>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(2, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="8">
                        <div>
                          <span class="color_red">¥ {{ item.Price | NumFormat }}</span>
                          <span class="marlt_10 font_12 color_gray" v-if="item.largessPrice">赠送：¥ {{ item.largessPrice }}</span>
                        </div>
                      </el-col>
                      <el-col :span="16">
                        <div class="text_right font_12">支付金额：{{ item.PayAmount | NumFormat }}</div>
                      </el-col>
                    </el-col>
                    <el-col :span="24" v-if="item.SavingCardRechargeRules.length > 0" class="dis_flex flex_dir_row flex_wrap">
                      <!-- never always -->
                      <el-card
                        shadow="never"
                        v-for="(RecharPrice, index) in item.SavingCardRechargeRules"
                        :key="index"
                        :body-style="{
                          padding: '0px',
                          padding: '10px',
                          'min-width': '100px',
                        }"
                        class="marrt_10 martp_10"
                        :class="RechargeRulesClass(item, index)"
                        @click.native="selectRechargeRules(item, RecharPrice, index)"
                      >
                        <div class="font_15 font_weight_600">
                          {{ RecharPrice.Price | NumFormat }}
                        </div>
                        <div class="font_13 color_666">赠额：{{ RecharPrice.LargessPrice | NumFormat }}</div>
                      </el-card>
                    </el-col>
                  </el-col>

                  <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息: {{ item.Remark }} </el-col>

                  <el-col v-if="item.handleTypeList.length" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="employeeHandleClick(2, selectSavingCard, item, index)">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee, employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handler, handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--时效卡-->
              <div v-if="selectTimeCard.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="3">时效卡</el-col>
                    <el-col :span="3">数量</el-col>
                    <el-col :span="3">金额</el-col>
                    <el-col :span="3">当前体重(斤)</el-col>
                    <el-col :span="3">目标体重(斤)</el-col>
                    <el-col :span="3">目标减重(斤)</el-col>
                    <el-col :span="2">欠款</el-col>
                    <el-col :span="2" style="margin-left:28px;">赠送</el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item, index) in selectTimeCard" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="3">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <span class="marlt_5 color_primary cursor_pointer">
                            <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="editProjectRemarkClick(item)"></el-button>
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="3">
                        <el-input-number :min="1" size="mini" style="width: 100px" v-model="item.number" @change="numberChange(item)"></el-input-number>
                      </el-col>
                      <el-col :span="3">
                        <span class="marrt_15">{{ parseFloat(item.TotalAmount) | NumFormat }}</span>
                        <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="savingCardDeductionClick(3, selectTimeCard, item, index)" v-if="!item.IsLargess"></el-button>
                      </el-col>
                      <!-- 测试 -->
                      <el-col :span="3">
                        <el-input v-model="item.CurrentWeight" :min="0" :disabled="item.TargetWeight == '' ? true : false" placeholder size="mini" type="number" v-enter-number2 style="width: 100px" @blur="ddddd(item)">
                          <!-- <template slot="append">斤</template> -->
                        </el-input>
                      </el-col>
                      <el-col :span="3">
                        <el-input v-model="item.CustomerTargetWeight" :min="0" :disabled="item.TargetWeight == '' ? true : false" placeholder size="mini" type="number" v-enter-number2 style="width: 100px" @blur="ddddd(item)">
                          <!-- <template slot="append">斤</template> -->
                        </el-input>
                      </el-col>
                      <el-col :span="3">
                        <el-input v-model="item.TargetWeight" disabled :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 110px">
                          <template slot="append">斤</template>
                        </el-input>
                      </el-col>

                      <el-col :span="3">
                        <el-input v-model="item.ArrearAmount" :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 70px" @input="arrearChange(item)" :disabled="item.IsLargess"></el-input>
                      </el-col>
                      <el-col :span="2" v-show="item.IsAllowLargess">
                        <el-checkbox v-model="item.IsLargess" @change="largessChange(item)"></el-checkbox>
                      </el-col>
                      <el-col :span="1" :offset="item.IsAllowLargess ? 0 : 2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(3, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="4">
                        <div class="color_red">¥ {{ item.Price | NumFormat }}</div>
                      </el-col>
                      <el-col :span="20">
                        <div class="text_right font_12">
                          <!-- <span>支付金额：{{item.PayAmount | NumFormat}}</span> -->
                          <span class="font_12">支付金额：{{ (item.IsLargess ? 0 : item.PayAmount) | NumFormat }}</span>
                          <span class="color_gray font_12 marlt_15" v-if="item.discountPrice != 0">
                            手动改价：
                            <span class="color_red" v-if="item.discountPrice > 0">-{{ item.discountPrice | NumFormat }}</span>
                            <span class="color_green" v-else>+{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDiscountPrice > 0">
                            卡优惠：
                            <span class="color_red">- {{ item.CardDiscountPrice | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDeductionAmount > 0">
                            卡抵扣：
                            <span class="color_red">- {{ item.CardDeductionAmount | NumFormat }}</span>
                          </span>
                        </div>
                      </el-col>
                    </el-col>
                  </el-col>

                  <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息: {{ item.Remark }} </el-col>

                  <el-col v-if="item.handleTypeList.length" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="employeeHandleClick(3, selectTimeCard, item, index)">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee, employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handler, handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--通用次卡-->
              <div v-if="selectGeneralCard.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">通用次卡</el-col>
                    <el-col :span="5">数量</el-col>
                    <el-col :span="5">金额</el-col>
                    <el-col :span="5">欠款</el-col>
                    <el-col :span="2">赠送</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item, index) in selectGeneralCard" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="6">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <span class="font_12 color_gray">× {{ item.Times }}次</span>
                          <span class="marlt_10 color_primary cursor_pointer">
                            <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="editProjectRemarkClick(item)"></el-button>
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="5">
                        <el-input-number :min="1" size="mini" style="width: 100px" v-model="item.number" @change="numberChange(item)"></el-input-number>
                      </el-col>
                      <el-col :span="5">
                        <span class="marrt_15">{{ parseFloat(item.TotalAmount) | NumFormat }}</span>
                        <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="savingCardDeductionClick(4, selectGeneralCard, item, index)" v-if="!item.IsLargess"></el-button>
                      </el-col>
                      <el-col :span="5">
                        <el-input v-model="item.ArrearAmount" :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 70px" @input="arrearChange(item)" :disabled="item.IsLargess"></el-input>
                      </el-col>
                      <el-col :span="2" v-show="item.IsAllowLargess">
                        <el-checkbox v-model="item.IsLargess" @change="largessChange(item)"></el-checkbox>
                      </el-col>
                      <el-col :span="1" :offset="item.IsAllowLargess ? 0 : 2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(4, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="4">
                        <div class="color_red">¥ {{ item.Price | NumFormat }}</div>
                      </el-col>
                      <el-col :span="20">
                        <div class="text_right font_12">
                          <!-- <span>支付金额： {{item.PayAmount | NumFormat}}</span> -->
                          <span class="font_12">支付金额：{{ (item.IsLargess ? 0 : item.PayAmount) | NumFormat }}</span>
                          <span class="color_gray font_12 marlt_15" v-if="item.discountPrice != 0">
                            手动改价：
                            <span class="color_red" v-if="item.discountPrice > 0">-{{ item.discountPrice | NumFormat }}</span>
                            <span class="color_green" v-else>+{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDiscountPrice">
                            卡优惠：
                            <span class="color_red">- {{ item.CardDiscountPrice | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDeductionAmount">
                            卡抵扣：
                            <span class="color_red">- {{ item.CardDeductionAmount | NumFormat }}</span>
                          </span>
                        </div>
                      </el-col>
                    </el-col>
                  </el-col>

                  <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息: {{ item.Remark }} </el-col>

                  <el-col v-if="item.handleTypeList.length" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="employeeHandleClick(4, selectGeneralCard, item, index)">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee, employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handler, handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--产品-->
              <div v-if="selectProduct.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">产品</el-col>
                    <el-col :span="5">数量</el-col>
                    <el-col :span="5">金额</el-col>
                    <el-col :span="5">欠款</el-col>
                    <el-col :span="2">赠送</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item, index) in selectProduct" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="6">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <span class="marlt_10 color_primary cursor_pointer">
                            <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="editProjectRemarkClick(item)"></el-button>
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="5">
                        <el-input-number :min="1" size="mini" style="width: 100px" v-model="item.number" @change="numberChange(item)"></el-input-number>
                      </el-col>
                      <el-col :span="5">
                        <span class="marrt_15">{{ parseFloat(item.TotalAmount) | NumFormat }}</span>
                        <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="savingCardDeductionClick(6, selectProduct, item, index)" v-if="!item.IsLargess"></el-button>
                      </el-col>
                      <el-col :span="5">
                        <el-input v-model="item.ArrearAmount" :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 70px" @input="arrearChange(item)" :disabled="item.IsLargess"></el-input>
                      </el-col>
                      <el-col :span="2" v-show="item.IsAllowLargess">
                        <el-checkbox v-model="item.IsLargess" @change="largessChange(item)"></el-checkbox>
                      </el-col>
                      <el-col :span="1" :offset="item.IsAllowLargess ? 0 : 2">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(6, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="4">
                        <div class="color_red">¥ {{ item.Price | NumFormat }}</div>
                      </el-col>
                      <el-col :span="20">
                        <div class="text_right font_12">
                          <!-- <span>支付金额：{{item.PayAmount | NumFormat}}</span> -->
                          <span class="font_12">支付金额：{{ (item.IsLargess ? 0 : item.PayAmount) | NumFormat }}</span>
                          <span class="color_gray font_12 marlt_15" v-if="item.discountPrice != 0">
                            手动改价：
                            <span class="color_red" v-if="item.discountPrice > 0">-{{ item.discountPrice | NumFormat }}</span>
                            <span class="color_green" v-else>+{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDiscountPrice > 0">
                            储值卡优惠：
                            <span class="color_red">- {{ item.CardDiscountPrice | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDeductionAmount > 0">
                            储值卡抵扣：
                            <span class="color_red">- {{ item.CardDeductionAmount | NumFormat }}</span>
                          </span>
                        </div>
                      </el-col>
                    </el-col>
                  </el-col>

                  <el-col v-if="item.Remark" :span="24" class="pad_10 border_bottom font_12 color_333">备注信息: {{ item.Remark }} </el-col>

                  <el-col v-if="item.handleTypeList.length" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="employeeHandleClick(6, selectProduct, item, index)">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee, employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handler, handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--套餐卡-->
              <div v-if="selectPackageCard.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="3">套餐卡</el-col>
                    <el-col :span="3">数量</el-col>
                    <el-col :span="3">当前体重(斤)</el-col>
                    <el-col :span="3">目标体重(斤)</el-col>
                    <el-col :span="3">目标减重</el-col>
                    <el-col :span="3">金额</el-col>
                    <el-col :span="3">欠款</el-col>
                    <el-col :span="2">赠送</el-col>
                    <!-- <el-col :span="1"></el-col> -->
                  </el-col>
                </el-row>
                <el-row class="border_bottom" v-for="(item, index) in selectPackageCard" :key="index">
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="3">
                        <div>
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <span class="marlt_15 color_primary cursor_pointer" @click="packDetailClick(item)">
                            <el-link type="primary" style="vertical-align: bottom" :underline="false">
                              查看
                              <i class="el-icon-view el-icon--right"></i>
                            </el-link>
                          </span>
                          <span class="marlt_5 color_primary cursor_pointer">
                            <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="editProjectRemarkClick(item)"></el-button>
                          </span>
                        </div>
                      </el-col>
                      <el-col :span="3">
                        <el-input-number :min="1" size="mini" style="width: 100px" v-model="item.number" @change="numberChange(item)"></el-input-number>
                      </el-col>
                      <el-col :span="3">
                        <div>-</div>
                      </el-col>
                      <el-col :span="3">
                        <div>-</div>
                      </el-col>
                      <el-col :span="3">
                        <div>-</div>
                      </el-col>
                      <el-col :span="3">
                        <span class="marrt_15">{{ parseFloat(item.TotalAmount) | NumFormat }}</span>
                        <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="savingCardDeductionClick(5, selectPackageCard, item, index)" v-if="!item.IsLargess"></el-button>
                      </el-col>
                      <el-col :span="3">
                        <div>{{ item.ArrearAmount }}</div>
                      </el-col>
                      <el-col :span="3" v-show="item.IsAllowLargess">
                        <el-checkbox v-model="item.IsLargess" @change="largessChange(item)"></el-checkbox>
                      </el-col>
                      <el-col :span="2" :offset="item.IsAllowLargess ? 0 : 2" style="margin-left:0;text-align:right;">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeClick(5, index, item)"></el-button>
                      </el-col>
                    </el-col>
                    <el-col :span="24" style="margin-top: 4px">
                      <el-col :span="4">
                        <div>
                          <span class="color_red">¥ {{ item.Price | NumFormat }}</span>
                        </div>
                      </el-col>
                      <el-col :span="20">
                        <div class="text_right">
                          <!-- <span class="font_12">支付金额：{{item.PayAmount | NumFormat}}</span> -->
                          <span class="font_12">支付金额：{{ (item.IsLargess ? 0 : item.PayAmount) | NumFormat }}</span>
                          <span class="color_gray font_12 marlt_15" v-if="item.discountPrice != 0">
                            手动改价：
                            <span class="color_red" v-if="item.discountPrice > 0">-{{ item.discountPrice | NumFormat }}</span>
                            <span class="color_green" v-else>+{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDiscountPrice > 0">
                            卡优惠：
                            <span class="color_red">- {{ item.CardDiscountPrice | NumFormat }}</span>
                          </span>
                          <span class="color_gray font_12 marlt_15" v-if="item.CardDeductionAmount > 0">
                            卡抵扣：
                            <span class="color_red">- {{ item.CardDeductionAmount | NumFormat }}</span>
                          </span>
                        </div>
                      </el-col>
                    </el-col>
                    <el-col :span="24" v-if="item.Remark" class="martp_10 font_12 color_333">备注信息: {{ item.Remark }} </el-col>
                  </el-col>
                  <el-col :span="24" v-if="item.isPackDetail">
                    <el-col :span="24" class="border_bottom pad_10 font_12" v-for="noLargess in item.noLargess" :key="`1-${noLargess.ID}`">
                      <el-col :span="24">
                        <el-col :span="4">
                          <span>{{ noLargess.Name }} × {{ noLargess.Amount }}</span>
                          <el-tag class="marlt_5" size="mini">{{ noLargess.cardType }}</el-tag>
                          <span class="marlt_5 color_primary cursor_pointer">
                            <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="editProjectRemarkClick(noLargess)"></el-button>
                          </span>
                        </el-col>
                        <el-col :span="2">× {{ noLargess.number }}</el-col>
                        <el-col :span="3">
                          <el-input v-if="noLargess.cardType == '时效卡'" v-model="noLargess.CurrentWeight" :disabled="noLargess.TargetWeight == '' ? true : false" @blur="getItem(noLargess)" :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 100px">
                            <!-- <template slot="append">斤</template> -->
                          </el-input>
                          <div v-else>-</div>
                        </el-col>
                        <el-col :span="3">
                          <el-input v-if="noLargess.cardType == '时效卡'" v-model="noLargess.CustomerTargetWeight" :disabled="noLargess.TargetWeight == '' ? true : false" @blur="getItem(noLargess)" :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 100px">
                            <!-- <template slot="append">斤</template> -->
                          </el-input>
                          <div v-else>-</div>
                        </el-col>
                        <el-col :span="3">
                          <el-input v-if="noLargess.cardType == '时效卡'" v-model="noLargess.TargetWeight" disabled :min="0" placeholder size="mini" type="number" v-enter-number2 style="width: 100px">
                            <template slot="append">斤</template>
                          </el-input>
                          <div v-else>-</div>
                        </el-col>
                        <el-col :span="3">{{ noLargess.totalAmount | NumFormat }}</el-col>
                        <el-col :span="2">
                          <el-input v-model="noLargess.ArrearAmount" placeholder="请输入欠款金额" size="mini" :min="0" type="number" v-enter-number2 @input="packageArrearChange(item, noLargess)" :disabled="item.IsLargess"></el-input>
                        </el-col>
                      </el-col>
                      <el-col :span="24" style="margin-top: 4px">
                        <el-col :span="12" class="color_red">¥ {{ noLargess.TotalPrice | NumFormat }}</el-col>
                        <el-col :span="12">
                          <div class="text_right">
                            <span class="font_12">支付金额：{{ noLargess.PayAmount | NumFormat }}</span>
                            <span class="color_gray font_12 marlt_15" v-if="item.discountPrice != 0 && noLargess.isCardType != 2">
                              手动改价：
                              <span class="color_red" v-if="noLargess.discountPrice > 0">-{{ noLargess.discountPrice | NumFormat }}</span>
                              <span class="color_green" v-else>+{{ mathAbsData(noLargess.discountPrice) | NumFormat }}</span>
                            </span>
                            <span class="color_gray font_12 marlt_15" v-if="noLargess.cardDiscountPrice > 0 && noLargess.isCardType != 2">
                              卡优惠：
                              <span class="color_red">-{{ parseFloat(noLargess.cardDiscountPrice) | NumFormat }}</span>
                            </span>
                            <span class="color_gray font_12 marlt_15" v-if="noLargess.cardDeductionAmount > 0 && noLargess.isCardType != 2">
                              卡抵扣：
                              <span class="color_red">-{{ parseFloat(noLargess.cardDeductionAmount) | NumFormat }}</span>
                            </span>
                          </div>
                        </el-col>
                      </el-col>
                      <el-col :span="24" v-if="noLargess.Remark" class="martp_10 font_12 color_333">备注信息: {{ noLargess.Remark }} </el-col>
                    </el-col>
                    <!-- 赠送 -->
                    <el-col :span="24" class="border_bottom pad_10 font_12" v-for="largess in item.largess" :key="`2-${largess.ID}`">
                      <el-col :span="24">
                        <el-col :span="3">
                          <div>
                            <el-tag class="marrt_5" size="mini" type="danger">赠</el-tag>
                            <span>{{ largess.Name }} × {{ largess.Amount }}</span>
                            <el-tag class="marlt_5" size="mini">{{ largess.cardType }}</el-tag>
                          </div>
                          <div class="color_red martp_5">¥{{ largess.TotalPrice | NumFormat }}</div>
                        </el-col>
                        <el-col :span="1">
                          <span class="marlt_5 color_primary cursor_pointer">
                            <el-button icon="el-icon-edit-outline" type="text" style="padding:unset" @click="editProjectRemarkClick(largess)"></el-button>
                          </span>
                        </el-col>
                        <el-col :span="12">× {{ largess.number }}</el-col>
                      </el-col>
                      <el-col v-if="largess.Remark" :span="24" class=" border_bottom font_12 color_333" style="padding:10px 0;">备注信息: {{ largess.Remark }} </el-col>
                    </el-col>
                  </el-col>

                  <el-col v-if="item.handleTypeList.length" :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row class="cursor_pointer" v-for="(handler, pIndex) in item.handleTypeList" :key="pIndex" @click.native="employeeHandleClick(5, selectPackageCard, item, index)">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item style="margin-bottom: 10px" v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}`">
                            <el-input class="employee_num" v-model="employee.Discount" size="mini" :min="0" :max="100" type="number" v-on:click.native.stop v-enter-number @input="handlerPercentChange(handler.Employee, employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5 font_16" style="color: #909399; vertical-align: middle" v-on:click.stop="removeHandleClick(handler, handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </el-scrollbar>
          </el-main>
          <el-footer class="border_top">
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <span class="font_14 color_maroon">待收款金额：¥ {{ PayAmount | NumFormat }}</span>
              </el-col>
              <el-col :span="7">
                <div class="dis_flex flex_y_center">
                  <span style="width:90px">购买属性 
                    <span style="color:red">*</span>
                  </span>
                  <el-select v-model="BuyProperty" size="small" placeholder="请选择购买属性">
                    <el-option value="新会员首次办卡"></el-option>
                    <el-option value="老会员转卡"></el-option>
                    <el-option value="老会员补卡" ></el-option>
                    <el-option value="减重积分划卡" ></el-option>
                    <el-option value="本年度1680会员升单" ></el-option>
                    <el-option value="本年度2580会员升单" ></el-option>
                    <el-option value="本年度5980会员升单" ></el-option>
                    <el-option value="本年度12900会员升单" ></el-option>
                    <el-option value="本年度680会员升单" ></el-option>
                    <el-option value="过往老会员复购" ></el-option>
                    <el-option value="活动卡" ></el-option>
                    <el-option value="常规团购" ></el-option>
                    <el-option value="团购刷单" ></el-option>
                    <el-option value="居家产品" ></el-option>
                    <el-option value="本年度16800升单" ></el-option>
                    <el-option value="本年度680复购" ></el-option>
                    <el-option value="本年度1680复购" ></el-option>
                    <el-option value="本年度2580复购" ></el-option>
                    <el-option value="本年度5980复购" ></el-option>
                    <el-option value="本年度12900复购" ></el-option>
                    <el-option value="本年度16800复购" ></el-option>
                    <el-option value="本年度19800复购" ></el-option>
                    <el-option value="780月卡升单" ></el-option>
                    <el-option value="1899暑期卡升单" ></el-option>
                    <el-option value="3999暑期卡升单" ></el-option>
                    <el-option value="780月卡复购" ></el-option>
                    <el-option value="1899暑期卡复购" ></el-option>
                    <el-option value="3999暑期卡复购" ></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="dis_flex flex_y_center" style="margin-left: 10px;">
                  <span style="width:90px">备注信息</span>
                  <el-input type="textarea" :rows="1" placeholder="请输入备注信息" v-model="Remark"></el-input>
                </div>
              </el-col>

              <el-col :span="3" class="text_right">
                <el-button type="primary" size="small" @click="billClick">收款</el-button>
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </el-col>
    </el-row>

    <!--经手人-->
    <el-dialog title="经手人" :visible.sync="dialogVisible" width="800px">
      <div>
        <el-row class="padbm_10">
          <el-col :span="8">
            <el-input placeholder="请输入员工编号、姓名" prefix-icon="el-icon-search" v-model="handlerName" size="small" clearable></el-input>
          </el-col>
        </el-row>
        <el-tabs v-model="tabHandle">
          <el-tab-pane :label="handler.Name" :name="`${index}`" v-for="(handler, index) in handlerList" :key="index">
            <el-row style="max-height: 300px; overflow-y: auto">
              <el-col :span="12" v-for="item in handler.Employee.filter((item) => !handlerName || item.EmployeeName.toLowerCase().includes(handlerName.toLowerCase()) || item.EmployeeID.toLowerCase().includes(handlerName.toLowerCase()))" :key="item.EmployeeID" class="marbm_10 dis_flex flex_y_center">
                <el-checkbox v-model="item.Checked" @change="handlerCheckedChange(handler.Employee, item)">
                  <span class="marrt_10">{{ item.EmployeeName }} [{{ item.EmployeeID }}]</span>
                </el-checkbox>
                <el-input placeholder v-model="item.Discount" style="width: 120px" type="number" size="mini" min="0" max="100" v-enter-number @input="handlerPercentChange(handler.Employee, item)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitHandleClick">确 定</el-button>
      </div>
    </el-dialog>
    <!--结账-->
    <el-dialog title="收银台" :visible.sync="dialogBill" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="900px">
      <div>
        <el-row>
          <el-col :span="8">
            <el-scrollbar class="el-scrollbar_height" style="height: 500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height: 32px">{{ entityName }}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">订单信息</span>
                </el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex: 3">{{ getBillDate() }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ customerFullName }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ customerPhoneNumber }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ userName }}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">消费明细</span>
                </el-divider>
                <template v-for="(item, index) in selectProject">
                  <div :key="index + item.type + item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 }} {{ item.Name }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.discountPrice > 0">-￥{{ item.discountPrice | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardDiscountPrice | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in selectSavingCard">
                  <div :key="index + item.type + item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">{{ index + 1 + selectProject.length }} {{ item.Name }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ (item.Amount / item.number).toFixed(2) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.LargessPrice > 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">充值赠送</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ (item.LargessPrice / item.number).toFixed(2) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Amount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in selectTimeCard">
                  <div :key="index + item.type + item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + selectProject.length + selectSavingCard.length }}
                        {{ item.Name }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.discountPrice > 0">-￥{{ item.discountPrice | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardDiscountPrice | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in selectGeneralCard">
                  <div :key="index + item.type + item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + selectProject.length + selectSavingCard.length + selectTimeCard.length }}
                        {{ item.Name }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.discountPrice > 0">-￥{{ item.discountPrice | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardDiscountPrice | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in selectProduct">
                  <div :key="index + item.type + item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + selectProject.length + selectSavingCard.length + selectTimeCard.length + selectGeneralCard.length }}
                        {{ item.Name }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.discountPrice > 0">-￥{{ item.discountPrice | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardDiscountPrice | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in selectPackageCard">
                  <div :key="index + item.type + item.ID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + selectProject.length + selectSavingCard.length + selectTimeCard.length + selectGeneralCard.length }}
                        {{ item.Name }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">(赠)</span>
                      </span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.number }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.discountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.discountPrice > 0">-￥{{ item.discountPrice | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.discountPrice) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardDiscountPrice != 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardDiscountPrice | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{ (parseFloat(Amount) + parseFloat(PricePreferentialAmount) + parseFloat(CardPreferentialAmount)).toFixed(2) | NumFormat }}</span>
                </div>
                <div class="dis_flex" v-if="PricePreferentialAmount != 0">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="PricePreferentialAmount > 0">-￥{{ PricePreferentialAmount | NumFormat }}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{ mathAbsData(PricePreferentialAmount) | NumFormat }}</span>
                </div>
                <div class="dis_flex" v-if="CardPreferentialAmount > 0">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{ CardPreferentialAmount | NumFormat }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单金额</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{ Amount | NumFormat }}</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
          <el-col :span="16">
            <el-row type="flex" align="middle" class="dialog_bill_detail">
              <el-col :span="24">
                <div class="marbm_10">
                  <span class="font_20">待收款：</span>
                  <span class="font_20">¥{{ PayAmount | NumFormat }}</span>
                </div>
                <div>
                  <span>订单金额：¥{{ Amount | NumFormat }}</span>
                  <span v-if="ArrearAmount > 0" class="color_gray font_12 marlt_10">
                    欠款：
                    <span class="color_red">-¥{{ ArrearAmount | NumFormat }}</span>
                  </span>
                  <span class="color_gray font_12 marlt_10" v-if="(parseFloat(CardDeductionAmount) + parseFloat(cardDeductionAmount)).toFixed(2) > 0">
                    <span>
                      卡抵扣：
                      <span class="color_red">-¥{{ (parseFloat(CardDeductionAmount) + parseFloat(cardDeductionAmount)).toFixed(2) | NumFormat }}</span>
                    </span>
                  </span>
                  <span v-if="PayCashAmount > 0" class="color_gray font_12 marlt_10">
                    付款：
                    <span class="color_red">-¥{{ PayCashAmount | NumFormat }}</span>
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-scrollbar class="el-scrollbar_height" style="height: 415px">
              <div class="tip" style="margin-top: 10px; margin-bottom: 0px" v-if="(savingCardAllGoods.length > 0) & (savingCardPrice > 0)">
                <i class="el-icon-warning-outline"></i>
                储值卡支付金额为￥{{ savingCardPrice | NumFormat }}，且不能卡抵扣支付。
              </div>
              <el-table :data="savingCardAllGoods" class="saving_discount martp_10" v-if="savingCardAllGoods.length > 0" :show-header="false" size="small">
                <el-table-column type="selection" width="55">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.checked" @change="savingCheckedAllChange(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label="商品信息">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.SavingCardName }}
                      <el-tag v-if="scope.row.IsLargess" size="mini" type="danger" class="marlt_5">赠</el-tag>
                    </div>
                    <div>可用金额：¥ {{ scope.row.TotalPrice | NumFormat }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="卡扣金额">
                  <template slot-scope="scope">
                    <el-input size="small" type="number" v-model="scope.row.TotalAmount" v-enter-number2 placeholder="请输入抵扣金额" @input="savingPriceAllChange(scope.row)" :disabled="!scope.row.checked"></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <el-table :data="payList" size="small" class="padtp_15" :show-header="false">
                <el-table-column prop="payName" label="选择收款方式">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.PayMethodID" placeholder="选择收款方式" size="small" clearable filterable @change="payMethodChange(scope.row)">
                      <el-option v-for="item in payTypeList" :key="item.ID" :label="item.Name" :value="item.ID"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="支付金额">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.Amount" size="small" type="number" v-enter-number2 placeholder="请输入收款金额" :disabled="scope.row.PayMethodID == ''" @input="payPriceChange(scope.row)"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button type="danger" icon="el-icon-close" circle size="mini" @click="removePayClick(scope.$index)" v-if="scope.$index + 1 != 1"></el-button>
                    <el-button type="primary" icon="el-icon-plus" circle size="mini" @click="addPayclick" v-if="scope.$index + 1 == payList.length"></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="martp_20" v-show="IsReturnedCommissionToo">
                返佣方式：
                <el-radio v-model="ReturnedCommissionType" :label="10">现金</el-radio>
                <el-radio v-model="ReturnedCommissionType" :label="20">卡金</el-radio>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogBill = false" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="submitPayClick" :loading="modalLoading" v-prevent-click size="small">确定收款</el-button>
      </div>
    </el-dialog>

    <!--储值卡抵扣(部分商品)-->
    <el-dialog title="商品价格调整/储值卡抵扣" :visible.sync="dialogDeduction" width="700px">
      <el-row class="border pad_10 marbm_10 radius5" style="height: 65px">
        <el-col :span="12" class="line_height_23">
          {{ selectGood.Name }}
          <span v-if="selectGood.Alias">({{ selectGood.Alias }})</span>
        </el-col>
        <el-col :span="6" class="color_gray font_13 line_height_23">¥ {{ selectGood.Price | NumFormat }} × {{ selectGood.number }}</el-col>
        <el-col :span="6" class="line_height_23">
          <div>
            ¥
            {{ (selectGood.Price * selectGood.number - selectGood.DeductionProjectAmount).toFixed(2) | NumFormat }}
          </div>
          <div class="color_gray font_12 line_height_23" v-show="selectGood.DeductionProjectAmount != 0">
            合计优惠：
            <span class="color_red" v-if="selectGood.DeductionProjectAmount > 0">-{{ selectGood.DeductionProjectAmount | NumFormat }}</span>
            <span class="color_green" v-else>+{{ mathAbsData(selectGood.DeductionProjectAmount) | NumFormat }}</span>
          </div>
        </el-col>
      </el-row>
      <template v-if="showModifyPrices">
        <el-row class="border pad_10 marbm_10 radius5" type="flex" align="middle" v-if="selectGood.isModify">
          <el-col :span="18">
            <span>手动改价:</span>
            <span class="mar_0_15">¥ {{ selectGood.Amount | NumFormat }}</span>
            <el-button type="text" @click="selectGood.isModify = false" size="mini">改价</el-button>
          </el-col>
          <el-col :span="6" class="color_gray font_12" v-if="selectGood.discountPrice != 0">
            <span>手动改价：</span>
            <span class="color_red" v-if="selectGood.discountPrice > 0">-{{ selectGood.discountPrice | NumFormat }}</span>
            <span class="color_green" v-else>+{{ mathAbsData(selectGood.discountPrice) | NumFormat }}</span>
          </el-col>
        </el-row>
        <el-row class="border pad_10 marbm_10 radius5" type="flex" align="middle" v-else>
          <el-col :span="4">
            <span>手动改价</span>
          </el-col>
          <el-col :span="14">
            <el-row type="flex" align="middle">
              <el-col :span="10">
                <el-input size="mini" v-model="selectGood.Amount" type="number" v-enter-number2 @input="amountChange(selectGood)">
                  <template slot="prepend">¥</template>
                </el-input>
              </el-col>
              <el-col :span="2" class="text_center">
                <i class="el-icon-sort"></i>
              </el-col>
              <el-col :span="10">
                <el-input size="mini" v-model="selectGood.discount" type="number" v-enter-number2 @input="discountChange(selectGood)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" class="text_right">
            <el-button type="primary" size="mini" @click="modifyChange(selectGood)">确认</el-button>
          </el-col>
        </el-row>
      </template>

      <el-table class="saving_discount radius5" :data="savingCardAll" size="small" v-if="savingCardAll.length > 0" :show-header="false" max-height="270px">
        <el-table-column type="selection" width="55">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked" @change="savingCardCheckedChange(selectGood, scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="储值卡">
          <template slot-scope="scope">
            <div class="font_14">
              {{ scope.row.SavingCardName }}
              <el-tag v-if="scope.row.IsLargess" size="mini" type="danger" class="marrt_5">赠</el-tag>
              <span class="color_gray font_12" v-if="scope.row.PriceType == 1">{{ scope.row.DiscountPrice }}折</span>
              <span class="color_gray font_12" v-else>¥ {{ scope.row.DiscountPrice | NumFormat }}</span>
            </div>
            <div class="font_12">可用金额：¥ {{ scope.row.TotalPrice | NumFormat }}</div>
          </template>
        </el-table-column>
        <el-table-column label="卡扣金额">
          <template slot-scope="scope">
            <el-input :disabled="!scope.row.checked" size="small" type="number" v-model="scope.row.TotalAmount" v-enter-number2 placeholder="请输入抵扣金额" @input="savingCardPriceChange(selectGood, scope.row)"></el-input>
            <div v-if="scope.row.cardDiscountPrice > 0" class="color_red">卡优惠：-{{ scope.row.cardDiscountPrice | NumFormat }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-row type="flex" align="middle">
          <el-col :span="18" class="text_left font_14">
            <div>
              <span>
                <span>支付金额：</span>
                <span class="color_red">¥ {{ selectGood.PayAmount | NumFormat }}</span>
              </span>
              <span class="font_12 color_gray" v-if="selectGood.CardDeductionAmount > 0">
                （
                <span>卡抵扣：</span>
                <span>-¥ {{ selectGood.CardDeductionAmount | NumFormat }}</span
                >）
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button @click="dialogDeduction = false" size="small">取消</el-button>
            <el-button type="primary" @click="submitSavingCard" size="small">确认</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!--储值卡抵扣(套餐卡)-->
    <el-dialog title="套餐价格调整/储值卡抵扣" :visible.sync="dialogDeductionPackage" width="700px">
      <template slot="title">
        <div>
          套餐价格调整/储值卡抵扣
          <span class="color_red font_13">（注：调整价格不能低于储值卡金额）</span>
        </div>
      </template>
      <el-row class="border pad_10 marbm_10 radius5" style="height: 65px">
        <el-col :span="12" class="line_height_23">
          <div>
            {{ selectGood.Name }}
            <span v-if="selectGood.Alias">({{ selectGood.Alias }})</span>
          </div>
          <div class="color_gray font_12" v-if="selectGood.Price - selectGood.DeductPrice > 0">储值卡金额：{{ ((selectGood.Price - selectGood.DeductPrice) * selectGood.number) | NumFormat }}</div>
        </el-col>
        <el-col :span="6" class="color_gray font_13 line_height_23">¥ {{ selectGood.Price | NumFormat }} × {{ selectGood.number }}</el-col>
        <el-col :span="6" class="line_height_23">
          <div>¥ {{ selectGood.Amount | NumFormat }}</div>
          <div class="color_gray font_12 line_height_23" v-show="selectGood.DeductionProjectAmount != 0">
            合计优惠：
            <span class="color_red" v-if="selectGood.DeductionProjectAmount > 0">-{{ selectGood.DeductionProjectAmount | NumFormat }}</span>
            <span class="color_green" v-else>+{{ mathAbsData(selectGood.DeductionProjectAmount) | NumFormat }}</span>
          </div>
        </el-col>
      </el-row>
      <template v-if="showModifyPrices">
        <el-row class="border pad_10 marbm_10 radius5" type="flex" align="middle" v-if="selectGood.isModify">
          <el-col :span="18">
            <span>手动改价:</span>
            <span class="mar_0_15">¥ {{ selectGood.Amount | NumFormat }}</span>
            <el-button type="text" @click="selectGood.isModify = false" size="mini">改价</el-button>
          </el-col>
          <el-col :span="6" class="color_gray font_12" v-if="selectGood.discountPrice != 0">
            <span>手动改价：</span>
            <span class="color_red" v-if="selectGood.discountPrice > 0">-{{ selectGood.discountPrice | NumFormat }}</span>
            <span class="color_green" v-else>+{{ mathAbsData(selectGood.discountPrice) | NumFormat }}</span>
          </el-col>
        </el-row>
        <el-row class="border pad_10 marbm_10 radius5" type="flex" align="middle" v-else>
          <el-col :span="4">
            <span>手动改价</span>
          </el-col>
          <el-col :span="14">
            <el-row type="flex" align="middle">
              <el-col :span="10">
                <el-input size="mini" v-model="selectGood.Amount" type="number" v-enter-number2 @input="packageAmountChange(selectGood)" @blur="packageModifyChange(selectGood)">
                  <template slot="prepend">¥</template>
                </el-input>
              </el-col>
              <el-col :span="2" class="text_center">
                <i class="el-icon-sort"></i>
              </el-col>
              <el-col :span="10">
                <el-input size="mini" v-model="selectGood.discount" type="number" v-enter-number2 @input="discountChange(selectGood)" @blur="packageModifyChange(selectGood)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6" class="text_right">
            <el-button type="primary" size="mini" @click="packageModifyChange(selectGood)">确认</el-button>
          </el-col>
        </el-row>
      </template>
      <el-table class="saving_discount radius5" :data="savingCardAll" size="small" v-if="savingCardAll.length > 0" :show-header="false" max-height="270px">
        <el-table-column type="selection" width="55">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked" @change="packageSavingCardCheckedChange(selectGood, scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="储值卡">
          <template slot-scope="scope">
            <div class="font_14">
              {{ scope.row.SavingCardName }}
              <el-tag v-if="scope.row.IsLargess" size="mini" type="danger" class="marrt_5">赠</el-tag>
              <span class="color_gray font_12" v-if="scope.row.PriceType == 1">{{ scope.row.DiscountPrice }}折</span>
              <span class="color_gray font_12" v-else>¥ {{ scope.row.DiscountPrice }}</span>
            </div>
            <div class="font_12">可用金额：¥ {{ scope.row.TotalPrice | NumFormat }}</div>
          </template>
        </el-table-column>
        <el-table-column label="卡扣金额">
          <template slot-scope="scope">
            <el-input :disabled="!scope.row.checked" size="small" type="number" v-model="scope.row.TotalAmount" v-enter-number2 placeholder="请输入抵扣金额" @input="packageSavingCardPriceChange(selectGood, scope.row)"></el-input>
            <div v-if="scope.row.cardDiscountPrice > 0" class="color_red">卡优惠：-{{ scope.row.cardDiscountPrice }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-row type="flex" align="middle">
          <el-col :span="18" class="text_left font_14">
            <div>
              <span>
                <span>支付金额：</span>
                <span class="color_red">¥ {{ selectGood.PayAmount | NumFormat }}</span>
              </span>
              <span class="font_12 color_gray" v-if="selectGood.CardDeductionAmount > 0">
                （
                <span>卡抵扣：</span>
                <span>-¥ {{ selectGood.CardDeductionAmount | NumFormat }}</span
                >）
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <el-button @click="dialogDeductionPackage = false" size="small">取消</el-button>
            <el-button type="primary" @click="submitSavingCardPackage" size="small">确认</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!--储值卡抵扣(通用)-->
    <!--<el-dialog title="选择" :visible.sync="dialogDeductionAll" width="700px">-->

    <!--<div slot="footer" class="dialog-footer">-->
    <!--<el-row type="flex" align="middle">-->
    <!--<el-col :span="18" class="text_left font_14">-->
    <!--<el-col :span="24" class="color_gray">-->
    <!--<span>储值卡抵扣：</span>-->
    <!--<span class="color_red">-¥{{cardDeductionAmount | NumFormat}}元</span>-->
    <!--</el-col>-->
    <!--<el-col :span="24" class="martp_5">-->
    <!--<span>还需支付金额</span>-->
    <!--<span class="color_red marlt_10">¥{{PayAmount | NumFormat}}</span>-->
    <!--</el-col>-->
    <!--</el-col>-->
    <!--<el-col :span="6">-->
    <!--<el-button @click="dialogDeductionAll = false" size="small">取消</el-button>-->
    <!--<el-button type="primary" @click="submitSavingCardAll" size="small">确认</el-button>-->
    <!--</el-col>-->
    <!--</el-row>-->
    <!--</div>-->
    <!--</el-dialog>-->

    <!--结账成功-->
    <el-dialog :visible.sync="dialogPay" width="450px" @close="closeSucceedDialog">
      <div class="text_center pad_15">
        <i class="el-icon-document" style="font-size: 80px; color: #999"></i>
        <div class="pad_15 color_primary font_weight_600 font_18">
          订单已结账成功
        </div>
      </div>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">销售订单号：</el-col>
        <el-col :span="12" class="text_right">{{ orderNumber }}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">订单金额：</el-col>
        <el-col :span="12" class="color_red text_right">¥{{ orderAmount }}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="5">订单备注：</el-col>
        <el-col :span="19">{{ Remark }}</el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogPay = false" size="small">继续开单</el-button>
        <el-button type="primary" @click="printOrderReceipt" :loading="printLoading" size="small">打印小票</el-button>
      </div>
    </el-dialog>

    <!-- 打印小票 -->
    <!-- 打印小票 -->
    <el-dialog :visible.sync="cashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="saleOrderDetail && entityName">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height: 500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height: 32px">{{ entityName }}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">订单信息</span>
                </el-divider>

                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex: 3">{{ saleOrderDetail.ID }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex: 3">{{ saleOrderDetail.BillDate }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ cashierReceipt.NameEncrypt ? formatName(saleOrderDetail.Name) : saleOrderDetail.Name }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ cashierReceipt.MobileEncrypt ? formatPhone(saleOrderDetail.PhoneNumber) : saleOrderDetail.PhoneNumber }}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ saleOrderDetail.EmployeeName }}</span>
                </div>

                <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
                  <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
                  <span class="flex_box font_12 text_right line_height_24">{{ saleOrderDetail.AddressDetail }}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">消费明细</span>
                </el-divider>
                <template v-for="(item, index) in saleOrderDetail.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 }} {{ item.ProjectName }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.Quantity }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.PricePreferentialAmount > 0">-￥{{ item.PricePreferentialAmount | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.PricePreferentialAmount) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardPreferentialAmount | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in saleOrderDetail.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">{{ index + 1 + saleOrderDetail.Project.length }} {{ item.SavingCardName }}</span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ (item.Amount / item.Quantity || 0).toFixed(2) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.LargessPrice > 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">充值赠送</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ (item.LargessPrice / item.Quantity || 0).toFixed(2) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.Quantity }}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleGoodsOriginPrice" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Amount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in saleOrderDetail.TimeCard">
                  <div :key="index + 'TimeCard' + item.TimeCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + saleOrderDetail.Project.length + saleOrderDetail.SavingCard.length }}
                        {{ item.TimeCardName }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.Quantity }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.PricePreferentialAmount > 0">-￥{{ item.PricePreferentialAmount | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.PricePreferentialAmount) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardPreferentialAmount | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in saleOrderDetail.GeneralCard">
                  <div :key="index + 'GeneralCard' + item.GeneralCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + saleOrderDetail.Project.length + saleOrderDetail.SavingCard.length + saleOrderDetail.TimeCard.length }}
                        {{ item.GeneralCardName }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.Quantity }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.PricePreferentialAmount > 0">-￥{{ item.PricePreferentialAmount | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.PricePreferentialAmount) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardPreferentialAmount | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in saleOrderDetail.Product">
                  <div :key="index + 'Product' + item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + saleOrderDetail.Project.length + saleOrderDetail.SavingCard.length + saleOrderDetail.TimeCard.length + saleOrderDetail.GeneralCard.length }}
                        {{ item.ProductName }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.Quantity }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.PricePreferentialAmount > 0">-￥{{ item.PricePreferentialAmount | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.PricePreferentialAmount) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardPreferentialAmount | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item, index) in saleOrderDetail.PackageCard">
                  <div :key="index + 'PackageCard' + item.PackageCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex: 2">
                        {{ index + 1 + saleOrderDetail.Project.length + saleOrderDetail.SavingCard.length + saleOrderDetail.TimeCard.length + saleOrderDetail.GeneralCard.length + saleOrderDetail.Product.length }}
                        {{ item.PackageCardName }}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex: 1">￥{{ item.Price | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">数量</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">{{ item.Quantity }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.PricePreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">手动改价</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.PricePreferentialAmount > 0">-￥{{ item.PricePreferentialAmount | NumFormat }}</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>+￥{{ mathAbsData(item.PricePreferentialAmount) | NumFormat }}</span>
                    </div>
                    <div class="dis_flex" v-if="item.CardPreferentialAmount != 0 && cashierReceipt.SalePromotions">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">卡优惠</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1">-￥{{ item.CardPreferentialAmount | NumFormat }}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex: 2">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex: 1" v-else>￥{{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{ saleOrderDetail.TotalAmount | NumFormat }}</span>
                </div>
                <div v-if="saleOrderDetail.SaleBillPay && saleOrderDetail.SaleBillPay.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="pay in saleOrderDetail.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{ pay.Name }}</span>
                      <span class="flex_box text_right line_height_24">¥ {{ pay.Amount | NumFormat }}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="saleOrderDetail.SaleBillPaySavingCardDeduction && saleOrderDetail.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="cardPay in saleOrderDetail.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{ cardPay.Name }}</span>
                      <span class="flex_box text_right line_height_24">¥ {{ cardPay.TotalAmount | NumFormat }}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.PricePreferentialAmount != 0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="saleOrderDetail.PricePreferentialAmount > 0">-￥{{ saleOrderDetail.PricePreferentialAmount | NumFormat }}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{ mathAbsData(saleOrderDetail.PricePreferentialAmount) | NumFormat }}</span>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.CardPreferentialAmount > 0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{ saleOrderDetail.CardPreferentialAmount | NumFormat }}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{ cashierReceipt.WriteTextFirst }}</span>
                  <span>{{ cashierReceipt.WriteTextSecond }}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cashierReceiptDialogVisible = false" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="getprintSaleBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="projectRemarkDialogVisible" title="备注信息" width="500px">
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" maxlength="100" show-word-limit placeholder="请输入备注信息" v-model="projectRemark"> </el-input>

      <div slot="footer" class="dialog-footer">
        <el-button @click="projectRemarkDialogVisible = false" size="small" :disabled="RemarkLoading">取 消</el-button>
        <el-button type="primary" @click="saveRemarkClick" :loading="RemarkLoading" v-prevent-click size="small">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Order/saleGoods";
import date from "@/components/js/date";
import cashierAPI from "@/api/iBeauty/Order/cashierReceipt";
import orderAPI from "@/api/iBeauty/Order/saleBill";
import printReceipt from "@/components/js/print";

var Enumerable = require("linq");
var socket;
export default {
  props: {
    billDate: String,
    isSupplement: Boolean,
    CustomerID: Number,
    customerFullName: String,
    customerPhoneNumber: String,
    SellPermission: Object,
    IsReturnedCommission: Boolean,
  },
  data() {
    return {
      BuyProperty:"",
      IsReturnedCommissionToo: null,
      RemarkLoading: false,
      projectRemarkDialogVisible: false,
      ReturnedCommissionType: 10,
      ReturnCommission: true,
      customerID: "",
      showModifyPrices: false,
      cashierReceiptDialogVisible: false, // 小票打印
      loading: false,
      printLoading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogBill: false,
      dialogDeduction: false,
      dialogDeductionPackage: false,
      dialogPay: false,
      goodsName: "",
      typeIndex: "0",
      goodsType: "Project",
      projectCategoryIndex: 0,
      productCategoryIndex: 0,
      savingCategoryIndex: 0,
      generalCategoryIndex: 0,
      timeCategoryIndex: 0,
      categorySubIndex: "0",
      packageCategoryIndex: 0,
      tabPane: "1",
      orderNumber: "",
      orderAmount: 0,
      BillID: "",
      Amount: 0, //订单金额
      PayAmount: 0, //待支付金额（待收款）
      PayCashAmount: 0, //现金支付金额
      payTotalPrice: 0,
      ArrearAmount: 0, //欠款金额
      cardDeductionAmount: 0,
      CardDeductionAmount: 0, // 储值卡抵扣金额
      PricePreferentialAmount: 0, //手动改价优惠金额
      CardPreferentialAmount: 0, //卡优惠金额
      savingCardPrice: 0, //储值卡金额
      Remark: "", //备注
      type: 1,
      handlerName: "",
      collapseName: [],
      goodsAll: [],
      goodsList: [], //全部商品
      projectList: [], //项目
      productList: [], //产品
      productCollapseName: [],
      generalCardList: [], //通用次卡
      timeCardList: [], //时效卡
      savingCardList: [], //储值卡
      packageCardList: [], //套餐卡
      projectCategory: [], //项目
      productCategory: [], //产品
      generalCardCategory: [], //通用次卡
      timeCardCategory: [], //时效卡
      savingCardCategory: [], //储值卡
      packageCardCategory: [], //套餐卡
      selectProject: [],
      selectProduct: [],
      selectGeneralCard: [],
      selectTimeCard: [],
      selectSavingCard: [],
      selectPackageCard: [],
      selectGoods: "",
      selectGood: "",
      goodsIndex: "",
      handlerList: [], //公共经手人列表
      productHandlerList: [], //产品经手人列表
      projectHandlerList: [], //项目经手人列表
      savingCardHandlerList: [], //储值卡经手人列表
      timeCardHandlerList: [], //时效卡经手人列表
      generalCardHandlerList: [], //通用次卡经手人列表
      packageCardHandlerList: [], //套餐卡经手人列表
      tabHandle: "0",
      savingCardAllGoods: [], // 通用储值卡
      savingCardSomeGoods: [], // 非通用储值卡
      savingCardAll: [],
      payTypeList: [],
      payList: [{ PayMethodID: "", Amount: "", price: 0 }],
      SavingCardDeduction: [],
      name: "",
      IsExistProduct: false,
      IsExistProject: false,
      IsExistGeneralCard: false,
      IsExistSavingCard: false,
      IsExistTimeCard: false,
      IsExistPackageCard: false,
      userName: "",
      entityName: "",
      saleOrderDetail: "",
      cashierReceipt: "", // 小票配置信息
      rowModule: {},
      rowModuleTo: {},
      currentRemarkItem: "",
      projectRemark: "",
    };
  },
  watch: {
    CustomerID(newValue) {
      var that = this;
      that.customerID = newValue;
      if (newValue != null) {
        that.savingCardAllGoodsData();
        that.savingCardSomeGoodsData();
      } else {
        that.savingCardAllGoods = [];
        that.savingCardSomeGoods = [];
        that.deductionAllReset();
        that.payAmountData();
      }
    },

    IsReturnedCommission(newVal, oldVal) {
      this.IsReturnedCommissionToo = newVal;
    },

    "$route.params"(newVal, oldVal) {
      console.log(newVal);
      if (JSON.stringify(newVal) == "{}") {
        return;
      } else {
        this.IsReturnedCommissionToo = newVal.IsReturnedCommission;
      }
    },

    // '$route':{
    //   handler(to,from){
    //   console.log(to,from)
    //   if(to.params == {}){
    //     console.log(this.IsReturnedCommissionToo)
    //     return this.IsReturnedCommissionToo

    //   }else {
    //     this.IsReturnedCommissionToo = to.params.IsReturnedCommission
    //   }
    //   },
    //   deep: true
    // }
  },

  methods: {
    /**  保存备注  */
    saveRemarkClick() {
      let that = this;
      that.currentRemarkItem.Remark = that.projectRemark;
      that.projectRemarkDialogVisible = false;
      that.projectRemark = "";
      that.currentRemarkItem = "";
    },
    /**  编辑项目 备注  */
    editProjectRemarkClick(item) {
      let that = this;
      that.projectRemarkDialogVisible = true;
      that.currentRemarkItem = item;
      that.projectRemark = that.currentRemarkItem.Remark;
    },
    ddddd(item) {
      this.rowModule = item;
      var val1 = Number(item.CurrentWeight);
      var val2 = Number(item.CustomerTargetWeight);
      var val3 = Number(item.TargetWeight);
      if (val1 !== "" && val2 !== "") {
        val3 = val1 - val2 < 0 ? "0" : val1 - val2;
        item.TargetWeight = val3.toFixed(2);
      }
    },
    getItem(item) {
      this.rowModuleTo = item;
      console.log(item);
      var a = parseInt(item.CurrentWeight);
      var b = parseInt(item.CustomerTargetWeight);
      var c = item.TargetWeight;
      if (a !== "" && b !== "") {
        c = a - b < 0 ? 0 : a - b;
        item.TargetWeight = c;
      }
    },
    mathAbsData: function(item) {
      return Math.abs(item);
    },
    /**  清除因时间改变选择的卡项   */
    clearDateCardItem() {
      let that = this;
      that.selectGeneralCard = [];
      that.selectTimeCard = [];
      that.selectSavingCard = [];
      that.selectPackageCard = [];
      that.payAmountData();
    },
    //获取开单时间
    getBillDate: function() {
      var that = this;
      return that.isSupplement ? that.billDate : date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss");
    },
    // 全部商品
    goodsData: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
        BillDate: that.getBillDate(),
      };
      API.getSaleGoods(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.goodsAll = [];
            that.goodsList = res.Data;
            if (res.Data.Project.length > 0) {
              that.goodsAll = res.Data.Project;
              that.goodsType = "Project";
            } else if (res.Data.SavingCard.length > 0) {
              that.goodsAll = res.Data.SavingCard;
              that.goodsType = "SavingCard";
            } else if (res.Data.TimeCard.length > 0) {
              that.goodsAll = res.Data.TimeCard;
              that.goodsType = "TimeCard";
            } else if (res.Data.GeneralCard.length > 0) {
              that.goodsAll = res.Data.GeneralCard;
              that.goodsType = "GeneralCard";
            } else if (res.Data.PackageCard.length > 0) {
              that.goodsAll = res.Data.PackageCard;
              that.goodsType = "PackageCard";
            } else if (res.Data.Product.length > 0) {
              that.goodsAll = res.Data.Product;
              that.goodsType = "Product";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 全部商品
    goodsDataInit: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        BillDate: that.getBillDate(),
      };
      API.getSaleGoods(params)
        .then((res) => {
          console.log(res);
          if (res.StateCode == 200) {
            that.goodsAll = [];
            that.goodsList = res.Data;
            if (res.Data.Project.length > 0) {
              that.goodsAll = res.Data.Project;
              that.goodsType = "Project";
              that.IsExistProject = true;
            } else if (res.Data.SavingCard.length > 0) {
              that.goodsAll = res.Data.SavingCard;
              that.goodsType = "SavingCard";
              that.IsExistSavingCard = true;
            } else if (res.Data.TimeCard.length > 0) {
              that.goodsAll = res.Data.TimeCard;
              that.goodsType = "TimeCard";
              that.IsExistTimeCard = true;
            } else if (res.Data.GeneralCard.length > 0) {
              that.goodsAll = res.Data.GeneralCard;
              that.goodsType = "GeneralCard";
              that.IsExistGeneralCard = true;
            } else if (res.Data.PackageCard.length > 0) {
              that.goodsAll = res.Data.PackageCard;
              that.goodsType = "PackageCard";
              that.IsExistPackageCard = true;
            } else if (res.Data.Product.length > 0) {
              that.goodsAll = res.Data.Product;
              that.goodsType = "Product";
              that.IsExistProduct = true;
            }
            //判断是否有对应的商品
            if (res.Data.Project.length > 0) {
              that.IsExistProject = true;
            } else {
              that.IsExistProject = false;
            }
            if (res.Data.SavingCard.length > 0) {
              that.IsExistSavingCard = true;
            } else {
              that.IsExistSavingCard = false;
            }
            if (res.Data.TimeCard.length > 0) {
              that.IsExistTimeCard = true;
            } else {
              that.IsExistTimeCard = false;
            }
            if (res.Data.GeneralCard.length > 0) {
              that.IsExistGeneralCard = true;
            } else {
              that.IsExistGeneralCard = false;
            }
            if (res.Data.PackageCard.length > 0) {
              that.IsExistPackageCard = true;
            } else {
              that.IsExistPackageCard = false;
            }
            if (res.Data.Product.length > 0) {
              that.IsExistProduct = true;
            } else {
              that.IsExistProduct = false;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 项目
    projectData: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
      };
      API.getSaleGoodsProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectList = [];
            that.projectCategory = res.Data;
            if (that.projectCategory[0] != undefined) {
              that.projectList = that.projectCategory[0].Child;
              that.projectList.forEach(function(item) {
                that.collapseName.push(item.ID);
              });
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 产品
    productData: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
      };
      API.getSaleGoodsProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            // that.productCategory = res.Data;
            // that.productList = [];
            // if (that.productCategory[0] != undefined) {
            //   that.productList = that.productCategory[0].Product;
            // }
            that.productList = [];
            that.productCategory = res.Data;
            if (that.productCategory[0] != undefined) {
              that.productList = that.productCategory[0].Child;
              that.productList.forEach(function(item) {
                that.productCollapseName.push(item.ID);
              });
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 通用次卡
    generalCardData: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
        BillDate: that.getBillDate(),
      };
      API.getSaleGoodsGeneralCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardCategory = res.Data;
            that.generalCardList = [];
            if (that.generalCardCategory[0] != undefined) {
              that.generalCardList = that.generalCardCategory[0].GeneralCard;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 时效卡
    timeCardData: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
        BillDate: that.getBillDate(),
      };
      API.getSaleGoodsTimeCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.timeCardCategory = res.Data;
            that.timeCardList = [];
            if (that.timeCardCategory[0] != undefined) {
              that.timeCardList = that.timeCardCategory[0].TimeCard;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 储值卡
    savingCardData: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
        BillDate: that.getBillDate(),
      };
      API.getSaleGoodsSavingCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardCategory = res.Data;
            that.savingCardList = [];
            if (that.savingCardCategory[0] != undefined) {
              that.savingCardList = that.savingCardCategory[0].SavingCard;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 套餐卡列表
    packageCardData: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.goodsName,
        BillDate: that.getBillDate(),
      };
      API.getSaleGoodsPackageCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardCategory = res.Data;
            that.packageCardList = [];
            if (that.packageCardCategory[0] != undefined) {
              that.packageCardList = that.packageCardCategory[0].PackageCard;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 项目经手人
    projectHandlerData: function() {
      var that = this;
      that.loading = true;
      API.getProjectHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 产品经手人
    productHandlerData: function() {
      var that = this;
      that.loading = true;
      API.getProductHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.productHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 通用次卡经手人
    generalHandlerData: function() {
      var that = this;
      that.loading = true;
      API.getGeneralCardHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 时效卡经手人
    timeCardHandlerData: function() {
      var that = this;
      that.loading = true;
      API.getTimeCardHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.timeCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 储值卡经手人
    savingCardHandlerData: function() {
      var that = this;
      that.loading = true;
      API.getSavingCardHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 套餐卡经手人
    packageCardHandlerData: function() {
      var that = this;
      that.loading = true;
      API.getPackageCardHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 支付方式
    salePayMethodData: function() {
      var that = this;
      that.loading = true;
      API.getSalePayMethod()
        .then((res) => {
          if (res.StateCode == 200) {
            that.payTypeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 会员通用储值卡
    savingCardAllGoodsData: function() {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
      };
      API.getSavingCardAllGoods(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardAllGoods = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 会员非通用储值卡（指定商品抵扣卡）
    savingCardSomeGoodsData: function() {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
      };
      API.getSavingCardSomeGoods(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardSomeGoods = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 可抵扣产品的储值卡列表
    savingCardProductData: function(item) {
      var that = this;
      if (that.customerID == "" || that.customerID == null) {
        return false;
      }
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        ProductID: item.ID,
      };
      API.getSavingCardProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardDeductionData(res.Data, item);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 可抵扣项目的储值卡列表
    savingCardProjecctData: function(item) {
      var that = this;
      that.loading = true;
      if (that.customerID == "" || that.customerID == null) {
        return false;
      }
      var params = {
        CustomerID: that.customerID,
        ProjectID: item.ID,
      };
      API.getSavingCardProject(params)
        .then((res) => {
          if (res.StateCode == 200) {
            // debugger;
            that.savingCardDeductionData(res.Data, item);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 可抵扣通用次卡的储值卡列表
    savingCardGeneralCardData: function(item) {
      var that = this;
      that.loading = true;
      if (that.customerID == "" || that.customerID == null) {
        return false;
      }
      var params = {
        CustomerID: that.customerID,
        GeneralCardID: item.ID,
      };
      API.getSavingCardGeneralCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardDeductionData(res.Data, item);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 可抵扣时效卡的储值卡列表
    savingCardTimeCardData: function(item) {
      var that = this;
      that.loading = true;
      if (that.customerID == "" || that.customerID == null) {
        return false;
      }
      var params = {
        CustomerID: that.customerID,
        TimeCardID: item.ID,
      };
      API.getSavingCardTimeCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardDeductionData(res.Data, item);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 可抵扣套餐卡的储值卡列表
    savingCardPackageCardData: function(item) {
      var that = this;
      that.loading = true;
      if (that.customerID == "" || that.customerID == null) {
        return false;
      }
      var params = {
        CustomerID: that.customerID,
        PackageCardID: item.ID,
      };
      API.getSavingCardPackageCard(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardDeductionData(res.Data, item);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    savingCardDeductionData: function(data, item) {
      var that = this;
      var savingCardAll = [];
      data.forEach(function(discount) {
        if (discount.PriceType == 1) {
          discount.DiscountPrice = discount.DiscountPrice * 10;
        }
      });
      that.savingCardSomeGoods.forEach(function(some) {
        data.forEach(function(project) {
          if (some.ID == project.ID) {
            savingCardAll.push({
              IsLargess: project.IsLargess,
              checked: false,
              AccountID: some.AccountID,
              Balance: some.Balance,
              ID: some.ID,
              LargessBalance: some.LargessBalance,
              SavingCardName: some.SavingCardName,
              TotalBalance: some.TotalBalance,
              TotalPrice: some.TotalBalance,
              Type: some.Type,
              DiscountPrice: project.DiscountPrice,
              PriceType: project.PriceType,
              TotalAmount: "",
              PreferentialAmount: 0,
              cardDiscountPrice: 0,
              cardDeductionAmount: 0,
            });
          }
        });
      });
      savingCardAll.forEach(function(saving) {
        item.savingCardDeduction.forEach(function(deduction) {
          if (saving.ID == deduction.ID) {
            saving.checked = true;
            saving.TotalAmount = deduction.TotalAmount || 0;
            saving.TotalBalance = (parseFloat(saving.TotalBalance) + parseFloat(deduction.TotalAmount)).toFixed(2);
            saving.TotalPrice = (saving.TotalBalance - saving.TotalAmount).toFixed(2);
            saving.PreferentialAmount = deduction.PreferentialAmount;
            saving.cardDiscountPrice = deduction.cardDiscountPrice;
            saving.cardDeductionAmount = deduction.cardDeductionAmount;
          }
        });
      });
      that.savingCardAll = Enumerable.from(savingCardAll)
        .where((i) => {
          return i.TotalBalance > 0;
        })
        .toArray();
    },
    // 搜索商品
    searchGoodsClick: function() {
      var that = this;
      that.tabPane = that.typeIndex;
      switch (that.typeIndex) {
        case "0":
          that.goodsData();
          break;
        case "1":
          that.projectData();
          break;
        case "2":
          that.savingCardData();
          break;
        case "3":
          that.timeCardData();
          break;
        case "4":
          that.generalCardData();
          break;
        case "5":
          that.packageCardData();
          break;
        case "6":
          that.productData();
          break;
        default:
          that.goodsData();
      }
    },
    // 清空
    clearClick: function() {
      var that = this;
      that.searchGoodsClick();
    },
    // 全部卡项切换
    goodsCategoryChange: function(item) {
      var that = this;
      that.goodsAll = that.goodsList[item];
      that.goodsType = item;
    },
    // 项目一级分类
    projectCategoryChange: function(item, index) {
      var that = this;
      that.collapseName = [];
      that.projectCategoryIndex = index;
      that.projectList = item.Child;
      that.projectList.forEach(function(item) {
        that.collapseName.push(item.ID);
      });
      that.categorySubIndex = "0";
    },
    // 二级分类
    // categorySubChange: function (index) {
    //   var that = this;
    //   that.categorySubIndex = index;
    // },
    // 产品一级分类
    productCategoryChange: function(item, index) {
      // var that = this;
      // that.productList = item.Product;
      // that.productCategoryIndex = index;

      var that = this;
      that.productCollapseName = [];
      that.productCategoryIndex = index;
      that.productList = item.Child;
      that.productCollapseName = Enumerable.from(that.productList)
        .select((item) => item.ID)
        .toArray();
    },
    //储值卡一级分类
    savingCategoryChange: function(item, index) {
      var that = this;
      that.savingCardList = item.SavingCard;
      that.savingCategoryIndex = index;
    },
    // 通用次卡一级分类
    generalCategoryChange: function(item, index) {
      var that = this;
      that.generalCardList = item.GeneralCard;
      that.generalCategoryIndex = index;
    },
    // 时效卡一级分类
    timeCategoryChange: function(item, index) {
      var that = this;
      that.timeCardList = item.TimeCard;
      that.timeCategoryIndex = index;
    },
    // 套餐卡一级分类
    packageCategoryChange: function(item, index) {
      var that = this;
      that.packageCardList = item.PackageCard;
      that.packageCategoryIndex = index;
    },
    // 锚点
    navChange: function(index, selector) {
      var that = this;
      var anchor = this.$el.querySelector(selector);
      that.$el.querySelector(".el_scrollbar_project").scrollTop = anchor.offsetTop;
    },
    // 商品选择
    goodsChange: function(row) {
      var that = this;
      switch (that.goodsType) {
        case "Project":
          that.projectChange(row);
          break;
        case "SavingCard":
          that.savingCardChange(row);
          break;
        case "TimeCard":
          that.timeCardChange(row);
          break;
        case "GeneralCard":
          that.generalCardChange(row);
          break;
        case "PackageCard":
          that.packageCardChange(row);
          break;
        case "Product":
          that.productChange(row);
          break;
      }
    },
    // 项目选择
    projectChange: function(row) {
      var that = this;
      var data = {
        type: 1,
        Alias: row.Alias,
        ID: row.ID,
        IsAllowLargess: row.IsAllowLargess,
        IsLargess: false,
        Name: row.Name,
        Price: row.Price,
        totalPrice: row.Price,
        number: 1,
        discount: 100,
        Amount: row.Price,
        PayAmount: row.Price,
        TotalAmount: row.Price,
        ArrearAmount: 0,
        isModify: true,
        DeductionProjectAmount: 0,
        discountPrice: 0,
        CardDeductionAmount: 0,
        CardDiscountPrice: 0,
        savingCardDeduction: [],
        handleTypeList: [],
        Remark: "",
      };
      that.projectHandlerList.forEach(function(item) {
        data.handleTypeList.push({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        });
      });
      that.selectProject.push(data);

      that.payAmountData();
    },
    // 储值卡选择
    savingCardChange: function(row) {
      var that = this;
      let recharItem = row.SavingCardRechargeRules.length > 0 ? row.SavingCardRechargeRules[0] : null;
      var data = {
        type: 2,
        Alias: row.Alias,
        ID: row.ID,
        IsAllowLargess: row.IsAllowLargess,
        IsLargess: false,
        Name: row.Name,
        Price: recharItem ? recharItem.Price : row.Price,
        LargessPrice: recharItem ? recharItem.LargessPrice : row.LargessPrice,
        largessPrice: recharItem ? recharItem.LargessPrice : row.LargessPrice,
        number: 1,
        Amount: recharItem ? recharItem.Price : row.Price,
        totalPrice: recharItem ? recharItem.Price : row.Price,
        PayAmount: recharItem ? recharItem.Price : row.Price,
        ArrearAmount: 0,
        LargessAmount: 0,
        handleTypeList: [],
        SavingCardRechargeRules: row.SavingCardRechargeRules, //阶梯价格
        RechargeRulesSelectIndex: 0,
        IsModifyLargessPrice: row.IsModifyLargessPrice, //是否可修改赠金
        IsModifyPrice: row.IsModifyPrice, // 是否可修改本金
        Remark: "",
      };
      that.savingCardHandlerList.forEach(function(item) {
        data.handleTypeList.push({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        });
      });
      that.selectSavingCard.push(data);
      that.payAmountData();
    },
    // 时效卡选择
    timeCardChange: function(row) {
      console.log(row);
      var that = this;
      var data = {
        type: 3,
        Alias: row.Alias,
        ID: row.ID,
        IsAllowLargess: row.IsAllowLargess,
        IsLargess: false,
        Name: row.Name,
        Price: row.Price,
        totalPrice: row.Price,
        number: 1,
        discount: 100,
        Amount: row.Price,
        PayAmount: row.Price,
        CurrentWeight: "",
        CustomerTargetWeight: "",
        TargetWeight: row.TargetWeight || "",
        TotalAmount: row.Price,
        ArrearAmount: 0,
        isModify: true,
        DeductionProjectAmount: 0,
        discountPrice: 0,
        CardDeductionAmount: 0,
        CardDiscountPrice: 0,
        savingCardDeduction: [],
        handleTypeList: [],
        Remark: "",
      };
      that.timeCardHandlerList.forEach(function(item) {
        data.handleTypeList.push({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        });
      });
      that.selectTimeCard.push(data);
      that.payAmountData();
    },
    // 通用次卡选择
    generalCardChange: function(row) {
      var that = this;
      var data = {
        type: 4,
        Alias: row.Alias,
        ID: row.ID,
        IsAllowLargess: row.IsAllowLargess,
        IsLargess: false,
        Name: row.Name,
        Price: row.Price,
        Times: row.Amount,
        totalPrice: row.Price,
        number: 1,
        discount: 100,
        Amount: row.Price,
        PayAmount: row.Price,
        TotalAmount: row.Price,
        ArrearAmount: 0,
        isModify: true,
        DeductionProjectAmount: 0,
        discountPrice: 0,
        CardDeductionAmount: 0,
        CardDiscountPrice: 0,
        savingCardDeduction: [],
        handleTypeList: [],
        Remark: "",
      };
      that.generalCardHandlerList.forEach(function(item) {
        data.handleTypeList.push({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        });
      });

      that.selectGeneralCard.push(data);
      that.payAmountData();
    },
    // 套餐卡选择
    packageCardChange: function(row) {
      var that = this;
      var largess = [];
      var noLargess = [];
      var largessProduct = Enumerable.from(row.PackageCardLargessGoods.Product)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          cardType: "产品",
          number: 1,
          isCardType: 6,
          Remark: "",
        }))
        .toArray();
      var largessProject = Enumerable.from(row.PackageCardLargessGoods.Project)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          cardType: "项目",
          number: 1,
          isCardType: 1,
          Remark: "",
        }))
        .toArray();
      var largessGeneralCard = Enumerable.from(row.PackageCardLargessGoods.GeneralCard)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          cardType: "通用次卡",
          number: 1,
          isCardType: 4,
          Remark: "",
        }))
        .toArray();
      var largessTimeCard = Enumerable.from(row.PackageCardLargessGoods.TimeCard)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          cardType: "时效卡",
          number: 1,
          isCardType: 3,
          Remark: "",
        }))
        .toArray();
      var largessSavingCard = Enumerable.from(row.PackageCardLargessGoods.SavingCard)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: 1,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          cardType: "储值卡",
          number: 1,
          isCardType: 2,
          Remark: "",
        }))
        .toArray();

      var product = Enumerable.from(row.PackageCardGoods.Product)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          OriginalPrice: val.OriginalPrice,
          ArrearAmount: "",
          cardType: "产品",
          number: 1,
          totalAmount: val.TotalPrice,
          TotalAmount: val.TotalPrice,
          PayAmount: val.TotalPrice,
          discountPrice: 0,
          cardDeductionAmount: 0,
          cardDiscountPrice: 0,
          isCardType: 6,
          Remark: "",
        }))
        .toArray();
      var project = Enumerable.from(row.PackageCardGoods.Project)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          OriginalPrice: val.OriginalPrice,
          ArrearAmount: "",
          cardType: "项目",
          number: 1,
          totalAmount: val.TotalPrice,
          TotalAmount: val.TotalPrice,
          PayAmount: val.TotalPrice,
          discountPrice: 0,
          cardDeductionAmount: 0,
          cardDiscountPrice: 0,
          isCardType: 1,
          Remark: "",
        }))
        .toArray();
      var generalCard = Enumerable.from(row.PackageCardGoods.GeneralCard)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          OriginalPrice: val.OriginalPrice,
          ArrearAmount: "",
          cardType: "通用次卡",
          number: 1,
          totalAmount: val.TotalPrice,
          TotalAmount: val.TotalPrice,
          PayAmount: val.TotalPrice,
          discountPrice: 0,
          cardDeductionAmount: 0,
          cardDiscountPrice: 0,
          isCardType: 4,
          Remark: "",
        }))
        .toArray();
      var timeCard = Enumerable.from(row.PackageCardGoods.TimeCard)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          OriginalPrice: val.OriginalPrice,
          ArrearAmount: "",
          cardType: "时效卡",
          number: 1,
          TargetWeight: val.TargetWeight || "",
          totalAmount: val.TotalPrice,
          TotalAmount: val.TotalPrice,
          PayAmount: val.TotalPrice,
          discountPrice: 0,
          cardDeductionAmount: 0,
          cardDiscountPrice: 0,
          isCardType: 3,
          Remark: "",
        }))
        .toArray();
      var savingCard = Enumerable.from(row.PackageCardGoods.SavingCard)
        .select((val) => ({
          ID: val.ID,
          Name: val.Name,
          Alias: val.Alias,
          Amount: val.Amount,
          Price: val.Price,
          TotalPrice: val.TotalPrice,
          OriginalPrice: val.OriginalPrice,
          ArrearAmount: "",
          cardType: "储值卡",
          number: 1,
          totalAmount: val.TotalPrice,
          PayAmount: val.TotalPrice,
          isCardType: 2,
          Remark: "",
        }))
        .toArray();

      largess = largess.concat(largessProduct, largessProject, largessGeneralCard, largessTimeCard, largessSavingCard);
      noLargess = noLargess.concat(product, project, generalCard, timeCard, savingCard);
      var data = {
        type: 5,
        Alias: row.Alias,
        ID: row.ID,
        IsAllowLargess: row.IsAllowLargess,
        IsLargess: false,
        Name: row.Name,
        Price: row.Price,
        totalPrice: row.Price,
        DeductPrice: row.DeductPrice,
        number: 1,
        discount: 100,
        Amount: row.Price,
        PayAmount: row.Price,
        TotalAmount: row.Price,
        ArrearAmount: 0,
        isModify: true,
        DeductionProjectAmount: 0,
        discountPrice: 0,
        CardDeductionAmount: 0,
        CardDiscountPrice: 0,
        largess: largess,
        noLargess: noLargess,
        isPackDetail: false,
        savingCardDeduction: [],
        handleTypeList: [],
        Remark: "",
      };
      that.packageCardHandlerList.forEach(function(item) {
        data.handleTypeList.push({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        });
      });

      that.selectPackageCard.push(data);
      that.payAmountData();
    },
    // 产品选择
    productChange: function(row) {
      var that = this;
      var data = {
        type: 6,
        Alias: row.Alias,
        ID: row.ID,
        IsAllowLargess: row.IsAllowLargess,
        IsLargess: false,
        Name: row.Name,
        Price: row.Price,
        totalPrice: row.Price,
        number: 1,
        discount: 100,
        Amount: row.Price,
        PayAmount: row.Price,
        TotalAmount: row.Price,
        ArrearAmount: 0,
        isModify: true,
        DeductionProjectAmount: 0,
        discountPrice: 0,
        CardDeductionAmount: 0,
        CardDiscountPrice: 0,
        savingCardDeduction: [],
        handleTypeList: [],
        Remark: "",
      };
      that.productHandlerList.forEach(function(item) {
        data.handleTypeList.push({
          ID: item.ID,
          Name: item.Name,
          Employee: [],
        });
      });
      that.selectProduct.push(data);
      that.payAmountData();
    },
    // 经手人
    employeeHandleClick: function(type, row, item, index) {
      var that = this;
      var emplayee = [];
      that.tabHandle = "0";
      switch (type) {
        case 1:
          that.handlerList = that.projectHandlerList;
          break;
        case 2:
          that.handlerList = that.savingCardHandlerList;
          break;
        case 3:
          that.handlerList = that.timeCardHandlerList;
          break;
        case 4:
          that.handlerList = that.generalCardHandlerList;
          break;
        case 5:
          that.handlerList = that.packageCardHandlerList;
          break;
        case 6:
          that.handlerList = that.productHandlerList;
          break;
      }
      item.handleTypeList.forEach(function(hand) {
        hand.Employee.forEach(function(emp) {
          emplayee.push({ ID: emp.ID, Discount: emp.Discount });
        });
      });
      that.handlerList.forEach(function(handler) {
        handler.Employee.forEach(function(emp) {
          emp.Checked = false;
          emp.Discount = "";
          emplayee.forEach(function(i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });
      that.type = type;
      that.selectGoods = row;
      that.goodsIndex = index;
      that.dialogVisible = true;
    },
    // 经手人确认选择
    submitHandleClick: function() {
      var that = this;
      var goodsHandlerList = JSON.parse(JSON.stringify(that.handlerList));

      goodsHandlerList.forEach(function(item) {
        item.Employee = Enumerable.from(item.Employee)
          .where(function(i) {
            return i.Checked;
          })
          .toArray();
      });

      that.selectGoods[that.goodsIndex].handleTypeList = goodsHandlerList;
      switch (that.type) {
        case 1:
          that.selectProject = that.selectGoods;
          break;
        case 2:
          that.selectSavingCard = that.selectGoods;
          break;
        case 3:
          that.selectTimeCard = that.selectGoods;
          break;
        case 4:
          that.selectGeneralCard = that.selectGoods;
          break;
        case 5:
          that.selectPackageCard = that.selectGoods;
          break;
        case 6:
          that.selectProduct = that.selectGoods;
          break;
      }
      that.dialogVisible = false;
    },
    // 删除经手人
    removeHandleClick: function(item, index) {
      item.Employee.splice(index, 1);
    },
    // 删除项目
    removeClick: function(type, index, item) {
      var that = this;
      switch (type) {
        case 1:
          that.selectProject.splice(index, 1);
          break;
        case 2:
          that.selectSavingCard.splice(index, 1);
          break;
        case 3:
          that.selectTimeCard.splice(index, 1);
          break;
        case 4:
          that.selectGeneralCard.splice(index, 1);
          break;
        case 5:
          that.selectPackageCard.splice(index, 1);
          break;
        case 6:
          that.selectProduct.splice(index, 1);
          break;
      }
      if (item.savingCardDeduction != undefined) {
        that.deductionReset(item);
      }
      that.payAmountData();
    },
    // 经手人选择
    handlerCheckedChange: function(row, item) {
      var that = this;
      var discount = 0;
      var employee = Enumerable.from(row)
        .where(function(i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function(emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
    },
    // 百分比
    handlerPercentChange: function(row, item) {
      var that = this;
      var discount = 0;
      if (item.Discount != "") {
        item.Discount = parseFloat(item.Discount);
      }
      if (item.Discount > 100) {
        item.Discount = 100;
      }
      var employee = Enumerable.from(row)
        .where(function(i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function(emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
    },
    // 储值卡抵扣(部分商品)
    savingCardDeductionClick: function(type, row, item, index) {
      item.PayAmount = parseFloat(item.PayAmount) + (parseFloat(item.ArrearAmount) || 0);
      item.ArrearAmount = 0;
      var that = this;
      that.payAmountData();
      that.savingCardAll = [];
      switch (type) {
        case 1:
          that.showModifyPrices = that.SellPermission.ModifyPrices_SaleProject;
          that.savingCardProjecctData(item);
          break;
        case 3:
          that.showModifyPrices = that.SellPermission.ModifyPrices_SaleTimeCard;
          that.savingCardTimeCardData(item);
          break;
        case 4:
          that.showModifyPrices = that.SellPermission.ModifyPrices_SaleGeneralCard;
          that.savingCardGeneralCardData(item);
          break;
        case 5:
          that.showModifyPrices = that.SellPermission.ModifyPrices_SalePackageCard;
          that.savingCardPackageCardData(item);
          break;
        case 6:
          that.showModifyPrices = that.SellPermission.ModifyPrices_SaleProduct;
          that.savingCardProductData(item);
          break;
      }
      that.type = type;
      that.selectGoods = row;
      that.selectGood = Object.assign({}, item);
      that.goodsIndex = index;
      item.ArrearAmount = 0;
      if (type == 5) {
        item.noLargess.forEach(function(i) {
          if (i.isCardType != 2) {
            i.ArrearAmount = "";
            i.PayAmount = (i.totalAmount - i.cardDeductionAmount).toFixed(2);
          } else {
            i.ArrearAmount = "";
          }
        });
        that.dialogDeductionPackage = true;
      } else {
        that.dialogDeduction = true;
      }
    },
    // 折扣
    discountChange: function(row) {
      var that = this;
      //清除选中的储值卡
      that.savingCardAll.forEach(function(item) {
        item.checked = false;
        item.TotalAmount = "";
        that.savingCardCheckedChange(row, item);
      });
      //计算金额
      row.Amount = ((row.Price * row.number * row.discount) / 100).toFixed(2);
      //计算优惠
      row.discountPrice = (row.totalPrice - row.Amount).toFixed(2);
      that.payPriceData(row);
    },
    // 折后金额
    amountChange: function(row) {
      var that = this;
      //清除选中的储值卡
      that.savingCardAll.forEach(function(item) {
        item.checked = false;
        item.TotalAmount = "";
        that.savingCardCheckedChange(row, item);
      });
      //计算折扣
      row.discount = parseInt((row.Amount / row.Price / row.number) * 100);
      //计算优惠
      row.discountPrice = (row.totalPrice - row.Amount).toFixed(2);
      that.payPriceData(row);
    },
    // 改价
    modifyChange: function(row) {
      var that = this;
      row.isModify = !row.isModify;
      if (row.Amount == "") {
        row.Amount = 0;
      }
      row.discountPrice = (row.totalPrice - row.Amount).toFixed(2);
      that.payPriceData(row);
    },
    // 储值卡抵扣选择
    savingCardCheckedChange: function(row, item) {
      var that = this;
      var amount;
      if (item.checked) {
        if (item.PriceType == 1) {
          var payAmount = (row.totalPrice - row.DeductionProjectAmount - row.CardDeductionAmount - row.ArrearAmount + parseFloat(item.cardDiscountPrice) + parseFloat(item.cardDeductionAmount)).toFixed(2);
          amount = ((item.DiscountPrice / 10) * payAmount).toFixed(2);
          if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
          } else {
            item.TotalAmount = amount;
          }
          item.PreferentialAmount = (item.TotalAmount / (item.DiscountPrice / 10)).toFixed(2);
        } else {
          amount = ((row.PayAmount * item.DiscountPrice) / row.Price).toFixed(2);
          if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
          } else {
            item.TotalAmount = (row.PayAmount / (row.Price / item.DiscountPrice)).toFixed(2);
          }

          item.PreferentialAmount = ((row.Price / item.DiscountPrice) * item.TotalAmount).toFixed(2);
        }
        item.cardDeductionAmount = item.TotalAmount;

        item.cardDiscountPrice = (item.PreferentialAmount - item.TotalAmount).toFixed(2);
        item.TotalPrice = (item.TotalBalance - item.TotalAmount).toFixed(2);
      } else {
        item.TotalAmount = "";
        item.cardDeductionAmount = 0;
        item.cardDiscountPrice = 0;
        item.PreferentialAmount = 0;
        item.TotalPrice = item.TotalBalance;
      }
      that.savingCardDeductionPrice(row);
      if (parseFloat(amount) < parseFloat(item.TotalBalance) && parseFloat(row.PayAmount) < 0.1) {
        item.cardDiscountPrice = (parseFloat(item.cardDiscountPrice) + parseFloat(row.PayAmount)).toFixed(2);
        row.PayAmount = 0;
        that.savingCardDeductionPrice(row);
      }
    },
    // 储值卡抵扣金额变化
    savingCardPriceChange: function(row, item) {
      var that = this;
      var amount;
      if (item.PriceType == 1) {
        let payAmount = (row.totalPrice - row.DeductionProjectAmount - row.CardDeductionAmount - row.ArrearAmount + parseFloat(item.cardDiscountPrice) + parseFloat(item.cardDeductionAmount)).toFixed(2);
        amount = ((item.DiscountPrice / 10) * payAmount).toFixed(2);
        if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
          if (parseFloat(item.TotalAmount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
            that.$message.error("卡扣金额不能大于" + item.TotalBalance + "元");
          }
        } else {
          if (parseFloat(item.TotalAmount) > parseFloat(amount)) {
            item.TotalAmount = amount;
            that.$message.error("卡扣金额不能大于" + amount + "元");
          }
        }
        item.PreferentialAmount = (item.TotalAmount / (item.DiscountPrice / 10)).toFixed(2);
      } else {
        var payAmount = (row.totalPrice - row.DeductionProjectAmount - row.CardDeductionAmount - row.ArrearAmount + parseFloat(item.cardDiscountPrice) + parseFloat(item.cardDeductionAmount)).toFixed(2);
        amount = ((payAmount * item.DiscountPrice) / row.Price).toFixed(2);
        if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
          if (parseFloat(item.TotalAmount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
            that.$message.error("卡扣金额不能大于" + item.TotalBalance + "元");
          }
        } else {
          if (parseFloat(item.TotalAmount) > parseFloat(amount)) {
            item.TotalAmount = amount;
            that.$message.error("卡扣金额不能大于" + amount + "元");
          }
        }
        item.PreferentialAmount = ((row.Price / item.DiscountPrice) * item.TotalAmount).toFixed(2);
      }
      item.cardDiscountPrice = (item.PreferentialAmount - item.TotalAmount).toFixed(2);
      item.TotalPrice = (item.TotalBalance - item.TotalAmount).toFixed(2);
      item.cardDeductionAmount = item.TotalAmount;
      that.savingCardDeductionPrice(row);
      if (parseFloat(amount) < parseFloat(item.TotalBalance) && parseFloat(row.PayAmount) < 0.1) {
        item.cardDiscountPrice = (parseFloat(item.cardDiscountPrice) + parseFloat(row.PayAmount)).toFixed(2);
        row.PayAmount = 0;
        that.savingCardDeductionPrice(row);
      }
    },
    // 套餐卡储值卡抵扣选择
    packageSavingCardCheckedChange: function(row, item) {
      var that = this;
      if (item.checked) {
        if (item.PriceType == 1) {
          let payAmount = (row.totalPrice - row.DeductionProjectAmount - row.CardDeductionAmount - (row.Price - row.DeductPrice) * row.number - row.ArrearAmount + parseFloat(item.cardDiscountPrice) + parseFloat(item.cardDeductionAmount)).toFixed(2);

          let amount = ((item.DiscountPrice / 10) * payAmount).toFixed(2);
          if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
          } else {
            item.TotalAmount = amount;
          }
          item.PreferentialAmount = (item.TotalAmount / (item.DiscountPrice / 10)).toFixed(2);
        } else {
          var payAmount = (row.totalPrice - row.DeductionProjectAmount - row.CardDeductionAmount - row.ArrearAmount - (row.Price - row.DeductPrice) * row.number).toFixed(2);

          if (payAmount / (row.DeductPrice / item.DiscountPrice) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
          } else {
            item.TotalAmount = (payAmount / (row.DeductPrice / item.DiscountPrice)).toFixed(2);
          }

          item.PreferentialAmount = ((row.DeductPrice / item.DiscountPrice) * item.TotalAmount).toFixed(2);
        }
        item.cardDeductionAmount = item.TotalAmount;
        item.cardDiscountPrice = (item.PreferentialAmount - item.TotalAmount).toFixed(2);
        item.TotalPrice = (item.TotalBalance - item.TotalAmount).toFixed(2);
      } else {
        item.TotalAmount = "";
        item.cardDeductionAmount = 0;
        item.cardDiscountPrice = 0;
        item.PreferentialAmount = 0;
        item.TotalPrice = item.TotalBalance;
      }
      that.savingCardDeductionPrice(row);
    },
    // 储值卡抵扣确认
    submitSavingCard: function() {
      var that = this;
      that.selectGoods[that.goodsIndex] = that.selectGood;
      var savingCardAll = that.savingCardAll;
      that.savingCardSomeGoods.forEach(function(item) {
        that.savingCardAll.forEach(function(sav) {
          if (item.ID == sav.ID) {
            item.TotalBalance = sav.TotalPrice;
          }
        });
      });
      savingCardAll = Enumerable.from(savingCardAll)
        .where(function(i) {
          return i.checked;
        })
        .toArray();
      that.selectGoods[that.goodsIndex].savingCardDeduction = savingCardAll;
      switch (that.type) {
        case 1:
          that.selectProject = that.selectGoods;
          break;
        case 2:
          that.selectSavingCard = that.selectGoods;
          break;
        case 3:
          that.selectTimeCard = that.selectGoods;
          break;
        case 4:
          that.selectGeneralCard = that.selectGoods;
          break;
        case 5:
          that.selectPackageCard = that.selectGoods;
          break;
        case 6:
          that.selectProduct = that.selectGoods;
          break;
      }
      that.dialogDeduction = false;
      that.payAmountData();
    },

    // 套餐卡折后金额
    packageAmountChange: function(row) {
      var that = this;
      //清除选中的储值卡
      that.savingCardAll.forEach(function(item) {
        item.checked = false;
        item.TotalAmount = "";
        that.savingCardCheckedChange(row, item);
      });

      //计算折扣
      row.discount = parseInt((row.Amount / row.Price / row.number) * 100);
      //计算优惠
      row.discountPrice = (row.totalPrice - row.Amount).toFixed(2);
      that.payPriceData(row);
    },
    // 套餐卡改价
    packageModifyChange: function(row) {
      var that = this;
      //判断调整价格不能低于储值卡金额
      if (row.Amount < row.totalPrice - row.DeductPrice * row.number) {
        row.Amount = (row.totalPrice - row.DeductPrice * row.number).toFixed(2);
        row.discount = parseInt((row.Amount / row.totalPrice) * 100);
        that.$message.error("调整价格不能低于储值卡金额");
      }
      //手动调整价格，配个到套餐卡明细
      var totalAmount = 0;
      var noLargess = Enumerable.from(row.noLargess)
        .where(function(i) {
          return i.isCardType != 2;
        })
        .toArray();
      row.noLargess = JSON.parse(JSON.stringify(row.noLargess));

      row.noLargess.forEach(function(item, i) {
        if (item.isCardType != 2) {
          if (i < noLargess.length - 1) {
            //总支付金额=(改动后价格-储值卡金额)*（单个）项目金额/（单个）非储值卡总计金额
            item.totalAmount = (((row.Amount - (row.Price - row.DeductPrice) * row.number) * item.TotalPrice) / row.DeductPrice).toFixed(2);
            //累计总支付金额
            totalAmount = (parseFloat(totalAmount) + parseFloat(item.totalAmount)).toFixed(2);
            //改价支付金额=总支付金额
            item.PayAmount = item.totalAmount;
            //手动优惠金额 = 总金额-支付金额
            item.discountPrice = (item.TotalPrice * row.number - item.totalAmount).toFixed(2);
            item.cardDiscountPrice = 0; //卡优惠金额
            item.cardDeductionAmount = 0; //卡抵扣金额
            item.TotalAmount = item.totalAmount; //总支付金额【卡抵扣时使用】= 总支付金额
          } else {
            //总支付金额=改价金额-已分配的支付金额-储值卡金额
            item.totalAmount = (row.Amount - totalAmount - (row.Price - row.DeductPrice) * row.number).toFixed(2);
            //改价支付金额=总支付金额
            item.PayAmount = item.totalAmount;
            //手动优惠金额 = 总金额-支付金额
            item.discountPrice = (item.TotalPrice * row.number - item.totalAmount).toFixed(2);
            item.cardDiscountPrice = 0; //卡优惠金额
            item.cardDeductionAmount = 0; //卡抵扣金额
            item.TotalAmount = item.totalAmount; //总支付金额【卡抵扣时使用】= 总支付金额
          }
        } else {
          item.totalAmount = item.TotalPrice * row.number;
        }
      });

      row.isModify = !row.isModify;
      if (row.Amount == "") {
        row.Amount = 0;
      }
      row.discountPrice = (row.totalPrice - row.Amount).toFixed(2);
      that.payPriceData(row);
    },
    // 套餐卡储值卡抵扣金额变化
    packageSavingCardPriceChange: function(row, item) {
      var that = this;
      if (item.PriceType == 1) {
        let payAmount = (row.totalPrice - row.DeductionProjectAmount - row.CardDeductionAmount - row.ArrearAmount - (row.Price - row.DeductPrice) * row.number + parseFloat(item.cardDiscountPrice) + parseFloat(item.cardDeductionAmount)).toFixed(2);
        let amount = ((item.DiscountPrice / 10) * payAmount).toFixed(2);
        if (parseFloat(amount) > parseFloat(item.TotalBalance)) {
          if (parseFloat(item.TotalAmount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
            that.$message.error("卡扣金额不能大于" + item.TotalBalance + "元");
          }
        } else {
          if (parseFloat(item.TotalAmount) > parseFloat(amount)) {
            item.TotalAmount = amount;
            that.$message.error("卡扣金额不能大于" + amount + "元");
          }
        }
        item.PreferentialAmount = (item.TotalAmount / (item.DiscountPrice / 10)).toFixed(2);
      } else {
        var payAmount = (row.totalPrice - row.DeductionProjectAmount - row.CardDeductionAmount - row.ArrearAmount - (row.Price - row.DeductPrice) * row.number + parseFloat(item.cardDiscountPrice) + parseFloat(item.cardDeductionAmount)).toFixed(2);
        var amount = (payAmount / (row.DeductPrice / item.DiscountPrice)).toFixed(2);
        if (payAmount / (row.DeductPrice / item.DiscountPrice) > parseFloat(item.TotalBalance)) {
          if (parseFloat(item.TotalAmount) > parseFloat(item.TotalBalance)) {
            item.TotalAmount = item.TotalBalance;
            that.$message.error("卡扣金额不能大于" + item.TotalBalance + "元");
          }
        } else {
          if (parseFloat(item.TotalAmount) > parseFloat(amount)) {
            item.TotalAmount = payAmount / (row.DeductPrice / item.DiscountPrice);
            that.$message.error("卡扣金额不能大于" + amount + "元");
          }
        }
        item.PreferentialAmount = ((row.DeductPrice / item.DiscountPrice) * item.TotalAmount).toFixed(2);
      }
      item.cardDiscountPrice = (item.PreferentialAmount - item.TotalAmount).toFixed(2);
      item.TotalPrice = (item.TotalBalance - item.TotalAmount).toFixed(2);
      item.cardDeductionAmount = item.TotalAmount;
      that.savingCardDeductionPrice(row);
    },
    // 储值卡抵扣总金额
    savingCardDeductionPrice: function(row) {
      var that = this;
      var CardDeductionAmount = 0;
      var cardDiscountPrice = 0;
      var savingCardAll = Enumerable.from(that.savingCardAll)
        .where(function(i) {
          return i.checked;
        })
        .toArray();
      savingCardAll.forEach(function(item) {
        var cardDeductionAmount = parseFloat(item.cardDeductionAmount) || 0;
        if (cardDeductionAmount == "") {
          cardDeductionAmount = 0;
        }
        CardDeductionAmount = (parseFloat(CardDeductionAmount) + cardDeductionAmount).toFixed(2);
        cardDiscountPrice = (parseFloat(cardDiscountPrice) + parseFloat(item.cardDiscountPrice)).toFixed(2);
      });
      row.CardDeductionAmount = CardDeductionAmount;
      row.CardDiscountPrice = cardDiscountPrice;
      that.payPriceData(row);
      if (row.type == 5) {
        that.packageSavingCardDeductionAmount(row);
      }
    },
    // 套餐卡抵扣明细金额变化
    packageSavingCardDeductionAmount: function(row) {
      var amount = 0;
      var cardDeductionAmount = 0;
      var cardDiscountPrice = 0;
      var noLargess = Enumerable.from(row.noLargess)
        .where(function(i) {
          return i.isCardType != 2;
        })
        .toArray();
      row.noLargess = JSON.parse(JSON.stringify(row.noLargess));
      row.noLargess.forEach(function(item) {
        if (item.isCardType != 2) {
          amount = (parseFloat(amount) + parseFloat(item.TotalAmount)).toFixed(2);
        }
      });
      row.noLargess.forEach(function(item, i) {
        if (item.isCardType != 2) {
          if (parseFloat(amount) != 0) {
            if (i < noLargess.length - 1) {
              //卡抵扣金额 =（ 商品支付金额*卡扣金额）/非储值卡商品总支付金额
              item.cardDeductionAmount = ((item.TotalAmount * row.CardDeductionAmount) / amount).toFixed(2);
              //累计卡抵扣金额= 累计卡抵扣金额+卡抵扣金额
              cardDeductionAmount = (parseFloat(cardDeductionAmount) + parseFloat(item.cardDeductionAmount)).toFixed(2);
              //卡优惠金额 = （ 商品支付金额*卡优惠金额）/非储值卡商品总支付金额
              item.cardDiscountPrice = ((item.TotalAmount * row.CardDiscountPrice) / amount).toFixed(2);
              //累计卡优惠金额= 累计卡优惠金额+卡优惠金额
              cardDiscountPrice = (parseFloat(cardDiscountPrice) + parseFloat(item.cardDiscountPrice)).toFixed(2);
              //卡支付金额 = 手动改价后的支付金额-卡优惠金额
              item.totalAmount = (item.TotalAmount - item.cardDiscountPrice).toFixed(2);
              //支付金额= 手动改价后的支付金额-卡扣金额
              item.PayAmount = (item.totalAmount - item.cardDeductionAmount).toFixed(2);
            } else {
              item.cardDeductionAmount = (row.CardDeductionAmount - cardDeductionAmount).toFixed(2);
              item.cardDiscountPrice = (row.CardDiscountPrice - cardDiscountPrice).toFixed(2);
              //卡支付金额 = 手动改价后的支付金额-卡优惠金额
              item.totalAmount = (item.TotalAmount - item.cardDiscountPrice).toFixed(2);
              //支付金额= 手动改价后的支付金额-卡扣金额
              item.PayAmount = (item.totalAmount - item.cardDeductionAmount).toFixed(2);
            }
          } else {
            item.totalAmount = 0;
          }
        } else {
          item.totalAmount = item.TotalPrice * item.number;
        }
      });
    },
    // 套餐卡明细欠款
    packageArrearChange: function(row, item) {
      var that = this;
      var ArrearAmount = 0;
      if (item.isCardType == 2) {
        if (parseFloat(item.ArrearAmount) > parseFloat(item.totalAmount)) {
          item.ArrearAmount = item.totalAmount;
          that.$message.error("欠款金额不能大于支付金额");
        }
        item.PayAmount = (item.totalAmount - item.ArrearAmount).toFixed(2);
      } else {
        if (parseFloat(item.ArrearAmount) > parseFloat((item.totalAmount - item.cardDeductionAmount).toFixed(2))) {
          item.ArrearAmount = (item.totalAmount - item.cardDeductionAmount).toFixed(2);
          that.$message.error("欠款金额不能大于支付金额");
        }
        item.PayAmount = (item.totalAmount - item.cardDeductionAmount - item.ArrearAmount).toFixed(2);
      }

      row.noLargess.forEach(function(largess) {
        var arrearAmount = parseFloat(largess.ArrearAmount) || 0;
        ArrearAmount = (parseFloat(ArrearAmount) + arrearAmount).toFixed(2);
      });
      row.ArrearAmount = ArrearAmount;
      that.payPriceData(row);
      that.payAmountData();
    },

    // 储值卡抵扣确认(套餐卡)
    submitSavingCardPackage: function() {
      var that = this;
      that.selectGoods[that.goodsIndex] = that.selectGood;
      var savingCardAll = that.savingCardAll;
      that.savingCardSomeGoods.forEach(function(item) {
        that.savingCardAll.forEach(function(sav) {
          if (item.ID == sav.ID) {
            item.TotalBalance = sav.TotalPrice;
          }
        });
      });
      savingCardAll = Enumerable.from(savingCardAll)
        .where(function(i) {
          return i.checked;
        })
        .toArray();
      that.selectGoods[that.goodsIndex].savingCardDeduction = savingCardAll;

      that.selectPackageCard = that.selectGoods;

      that.dialogDeductionPackage = false;
      that.payAmountData();
    },
    // 储值卡抵扣(通用)
    // savingCardDeductionAllClick: function() {
    //   var that = this;
    //   if (that.savingCardAllGoods.length == 0) {
    //     return false;
    //   }
    //   that.savingCardAllGoods = Enumerable.from(that.savingCardAllGoods)
    //     .select(val => ({
    //       checked: false,
    //       AccountID: val.AccountID,
    //       Balance: val.Balance,
    //       ID: val.ID,
    //       LargessBalance: val.LargessBalance,
    //       SavingCardName: val.SavingCardName,
    //       TotalBalance: val.TotalBalance,
    //       TotalPrice: val.TotalBalance,
    //       Type: val.Type,
    //       TotalAmount: ""
    //     }))
    //     .toArray();
    //   that.savingCardAllGoods.forEach(function(item) {
    //     that.SavingCardDeduction.forEach(function(card) {
    //       if (item.ID == card.ID) {
    //         item.checked = card.checked;
    //         item.TotalAmount = card.TotalAmount;
    //       }
    //     });
    //   });
    // },
    // 储值卡抵扣选择(通用)
    savingCheckedAllChange: function(item) {
      var that = this;
      // var savingCardPrice = 0;
      var PayAmount = 0;
      // that.selectSavingCard.forEach(function(item) {
      //   savingCardPrice = (
      //     parseFloat(savingCardPrice) + parseFloat(item.PayAmount)
      //   ).toFixed(2);
      // });
      // that.selectPackageCard.forEach(function(item) {
      //   item.noLargess.forEach(function(noLargess) {
      //     if (noLargess.isCardType == 2) {
      //       savingCardPrice = (
      //         parseFloat(savingCardPrice) + parseFloat(noLargess.PayAmount)
      //       ).toFixed(2);
      //     }
      //   });
      // });
      PayAmount = (parseFloat(that.PayAmount) - parseFloat(that.savingCardPrice) + parseFloat(that.PayCashAmount)).toFixed(2);
      if (item.checked) {
        if (parseFloat(item.TotalBalance) < parseFloat(PayAmount)) {
          item.TotalAmount = item.TotalBalance;
        } else {
          item.TotalAmount = PayAmount;
        }
        item.TotalPrice = (item.TotalBalance - item.TotalAmount).toFixed(2);
      } else {
        item.TotalAmount = "";
        item.TotalPrice = item.TotalBalance;
      }
      item.cardDeductionAmount = item.TotalAmount;
      that.savingDeductionPriceAll();
    },
    // 储值卡抵扣金额变化（通用）
    savingPriceAllChange: function(item) {
      var that = this;
      // var savingCardPrice = 0;
      var PayAmount = 0;
      // that.selectSavingCard.forEach(function(item) {
      //   savingCardPrice = (
      //     parseFloat(savingCardPrice) + parseFloat(item.PayAmount)
      //   ).toFixed(2);
      // });
      // that.selectPackageCard.forEach(function(item) {
      //   item.noLargess.forEach(function(noLargess) {
      //     if (noLargess.isCardType == 2) {
      //       savingCardPrice = (
      //         parseFloat(savingCardPrice) + parseFloat(noLargess.PayAmount)
      //       ).toFixed(2);
      //     }
      //   });
      // });
      PayAmount = (parseFloat(that.PayAmount) - parseFloat(that.savingCardPrice) + parseFloat(item.cardDeductionAmount) + parseFloat(that.PayCashAmount)).toFixed(2);
      if (parseFloat(item.TotalBalance) < parseFloat(PayAmount)) {
        if (parseFloat(item.TotalAmount) > parseFloat(item.TotalBalance)) {
          item.TotalAmount = item.TotalBalance;
          that.$message.error("卡扣金额不能大于" + item.TotalBalance + "元");
        }
      } else {
        if (parseFloat(item.TotalAmount) > parseFloat(PayAmount)) {
          item.TotalAmount = PayAmount;
          that.$message.error("卡扣金额不能大于" + PayAmount + "元");
        }
      }
      item.cardDeductionAmount = item.TotalAmount;
      item.TotalPrice = item.TotalBalance - item.TotalAmount;
      that.savingDeductionPriceAll();
    },
    // 储值卡抵扣金额(通用)
    savingDeductionPriceAll: function() {
      var that = this;
      var cardDeductionAmount = 0;
      var savingCardAllGoods = Enumerable.from(that.savingCardAllGoods)
        .where(function(i) {
          return i.checked;
        })
        .toArray();
      savingCardAllGoods.forEach(function(item) {
        cardDeductionAmount = (parseFloat(cardDeductionAmount) + (parseFloat(item.TotalAmount) || 0)).toFixed(2);
      });
      that.cardDeductionAmount = cardDeductionAmount;
      that.payAmountData();
    },
    // 储值卡抵扣确认(通用)
    // submitSavingCardAll: function() {
    //   var that = this;
    //   that.SavingCardDeduction = Enumerable.from(that.savingCardAllGoods)
    //     .where(function(i) {
    //       return i.checked;
    //     })
    //     .toArray();
    //   that.payAmountData();
    //   that.dialogDeductionAll = false;
    // },
    // 数量
    numberChange: function(row) {
      var that = this;
      row.discount = 100;
      row.discountPrice = 0;
      row.ArrearAmount = 0;
      if (row.type != 2) {
        that.deductionReset(row);
        row.savingCardDeduction = [];
        row.Amount = ((row.Price * row.number * row.discount) / 100).toFixed(2);
        row.totalPrice = ((row.Price * row.number * row.discount) / 100).toFixed(2);
        that.payPriceData(row);
      } else {
        row.LargessPrice = (row.largessPrice * row.number).toFixed(2);
        row.Amount = (row.Price * row.number).toFixed(2);
        row.totalPrice = row.Amount;
        row.PayAmount = row.Amount;
      }

      if (row.type == 5) {
        row.noLargess.forEach(function(item) {
          item.number = row.number;
          item.totalAmount = (row.number * item.TotalPrice).toFixed(2);
          item.TotalAmount = item.totalAmount;
          item.cardDeductionAmount = 0;
          item.cardDiscountPrice = 0;
          item.ArrearAmount = "";
          item.PayAmount = (row.number * item.TotalPrice).toFixed(2);
        });
        row.largess.forEach(function(item) {
          item.number = row.number;
        });
      }
      that.payAmountData();
    },
    // 赠送
    largessChange: function(row) {
      var that = this;
      if (row.IsLargess) {
        row.discount = 100;
        row.discountPrice = 0;
        that.deductionReset(row);
        row.savingCardDeduction = [];
        row.Amount = ((row.Price * row.number * row.discount) / 100).toFixed(2);
        row.TotalAmount = row.Amount;
        row.ArrearAmount = 0;
        row.PayAmount = 0;
        row.DeductionProjectAmount = 0;
        row.discountPrice = 0;
        row.CardDeductionAmount = 0;
        (row.CardDiscountPrice = 0), (row.totalPrice = row.Amount);

        if (row.type == 2) {
          row.LargessPrice = (row.largessPrice * row.number).toFixed(2);
        }
        if (row.type == 5) {
          row.noLargess.forEach(function(item) {
            item.number = row.number;
            item.totalAmount = (row.number * item.TotalPrice).toFixed(2);
            item.cardDeductionAmount = 0;
            item.cardDiscountPrice = 0;
            item.ArrearAmount = "";
            item.PayAmount = 0;
          });
        }
      } else {
        row.PayAmount = ((row.Price * row.number * row.discount) / 100).toFixed(2);
        if (row.type == 5) {
          row.noLargess.forEach(function(item) {
            item.number = row.number;
            item.totalAmount = (row.number * item.TotalPrice).toFixed(2);
            item.cardDeductionAmount = 0;
            item.cardDiscountPrice = 0;
            item.ArrearAmount = "";
            item.PayAmount = item.totalAmount;
          });
        }
      }

      that.payAmountData();
    },
    // 储值卡金额变化
    savingAmountChange: function(row) {
      var that = this;
      row.ArrearAmount = 0;
      row.Amount = row.Amount - 0;
      row.PayAmount = (row.Amount - row.ArrearAmount).toFixed(2);
      that.payAmountData();
    },
    // 欠款
    arrearChange: function(item) {
      var that = this;
      if (item.type == 2) {
        if (parseFloat(item.ArrearAmount) > parseFloat(item.Amount)) {
          that.$message.error("欠款金额不能大于支付金额");
          item.ArrearAmount = item.Amount;
        }
      } else {
        if (parseFloat(item.ArrearAmount) > parseFloat(item.Amount) - parseFloat(item.CardDiscountPrice) - parseFloat(item.CardDeductionAmount)) {
          that.$message.error("欠款金额不能大于支付金额");
          item.ArrearAmount = parseFloat(item.Amount) - parseFloat(item.CardDiscountPrice) - parseFloat(item.CardDeductionAmount);
        }
      }

      that.payPriceData(item);
      that.payAmountData();
    },
    payPriceData: function(item) {
      if (item.type == 2) {
        item.PayAmount = (item.Amount - (item.ArrearAmount || 0)).toFixed(2);
      } else {
        item.DeductionProjectAmount = (parseFloat(item.discountPrice) + parseFloat(item.CardDiscountPrice)).toFixed(2);
        item.PayAmount = (item.totalPrice - item.DeductionProjectAmount - item.CardDeductionAmount - item.ArrearAmount).toFixed(2);
        item.TotalAmount = (item.Amount - item.CardDiscountPrice).toFixed(2);
      }
    },
    // 卡抵扣重置
    deductionReset: function(row) {
      var that = this;
      row.CardDeductionAmount = 0;
      row.CardDiscountPrice = 0;
      that.savingCardSomeGoods.forEach(function(item) {
        row.savingCardDeduction.forEach(function(sav) {
          if (item.ID == sav.ID) {
            item.TotalBalance = (parseFloat(item.TotalBalance) + parseFloat(sav.TotalAmount)).toFixed(2);
          }
        });
      });
    },
    // 删除会员卡抵扣重置
    deductionAllReset: function() {
      var that = this;
      that.selectProject.forEach(function(item) {
        item.CardDeductionAmount = 0;
        item.CardDiscountPrice = 0;
        item.DeductionProjectAmount = 0;
        item.discountPrice = 0;
        item.discount = 100;
        item.Amount = (item.number * item.Price).toFixed(2);
        item.totalPrice = item.Amount;
        item.TotalAmount = item.Amount;
        item.ArrearAmount = 0;
        item.PayAmount = item.Amount;
        item.IsLargess = false;
        item.savingCardDeduction = [];
      });
      that.selectTimeCard.forEach(function(item) {
        item.CardDeductionAmount = 0;
        item.CardDiscountPrice = 0;
        item.DeductionProjectAmount = 0;
        item.discountPrice = 0;
        item.discount = 100;
        item.Amount = (item.number * item.Price).toFixed(2);
        item.totalPrice = item.Amount;
        item.TotalAmount = item.Amount;
        item.ArrearAmount = 0;
        item.PayAmount = item.Amount;
        item.IsLargess = false;
        item.savingCardDeduction = [];
      });
      that.selectGeneralCard.forEach(function(item) {
        item.CardDeductionAmount = 0;
        item.CardDiscountPrice = 0;
        item.DeductionProjectAmount = 0;
        item.discountPrice = 0;
        item.discount = 100;
        item.Amount = (item.number * item.Price).toFixed(2);
        item.totalPrice = item.Amount;
        item.TotalAmount = item.Amount;
        item.ArrearAmount = 0;
        item.PayAmount = item.Amount;
        item.IsLargess = false;
        item.savingCardDeduction = [];
      });
      that.selectPackageCard.forEach(function(item) {
        item.CardDeductionAmount = 0;
        item.CardDiscountPrice = 0;
        item.DeductionProjectAmount = 0;
        item.discountPrice = 0;
        item.discount = 100;
        item.Amount = (item.number * item.Price).toFixed(2);
        item.totalPrice = item.Amount;
        item.TotalAmount = item.Amount;
        item.ArrearAmount = 0;
        item.PayAmount = item.Amount;
        item.IsLargess = false;
        item.savingCardDeduction = [];
        item.noLargess.forEach(function(noLargess) {
          noLargess.cardDeductionAmount = 0;
          noLargess.cardDiscountPrice = 0;
          noLargess.discountPrice = 0;
          noLargess.TotalAmount = (noLargess.TotalPrice * noLargess.number).toFixed(2);
          noLargess.ArrearAmount = "";
          noLargess.totalAmount = noLargess.TotalAmount;
          noLargess.PayAmount = noLargess.TotalAmount;
        });
      });
      that.selectProduct.forEach(function(item) {
        item.CardDeductionAmount = 0;
        item.CardDiscountPrice = 0;
        item.DeductionProjectAmount = 0;
        item.discountPrice = 0;
        item.discount = 100;
        item.Amount = (item.number * item.Price).toFixed(2);
        item.totalPrice = item.Amount;
        item.TotalAmount = item.Amount;
        item.ArrearAmount = 0;
        item.PayAmount = item.Amount;
        item.IsLargess = false;
        item.savingCardDeduction = [];
      });
      that.selectSavingCard.forEach(function(item) {
        item.Amount = (item.number * item.Price).toFixed(2);
        item.LargessPrice = item.largessPrice;
        item.IsLargess = false;
      });
    },

    // 计算总金额
    payAmountData: function() {
      var that = this;
      var payAmount = 0;
      var TotalAmount = 0; //订单金额
      var ArrearAmount = 0; //欠款金额
      var PricePreferentialAmount = 0;
      var CardPreferentialAmount = 0;
      var CardDeductionAmount = 0;
      that.selectProject.forEach(function(item) {
        if (!item.IsLargess) {
          payAmount = (parseFloat(payAmount) + parseFloat(item.PayAmount)).toFixed(2);
          ArrearAmount = (parseFloat(ArrearAmount) + parseFloat(item.ArrearAmount || 0)).toFixed(2);
          TotalAmount = (parseFloat(TotalAmount) + parseFloat(item.TotalAmount)).toFixed(2);
          PricePreferentialAmount = (parseFloat(PricePreferentialAmount) + parseFloat(item.discountPrice)).toFixed(2);
          CardPreferentialAmount = (parseFloat(CardPreferentialAmount) + parseFloat(item.CardDiscountPrice)).toFixed(2);
          CardDeductionAmount = (parseFloat(CardDeductionAmount) + parseFloat(item.CardDeductionAmount)).toFixed(2);
        }
      });
      that.selectSavingCard.forEach(function(item) {
        payAmount = (parseFloat(payAmount) + parseFloat(item.PayAmount)).toFixed(2);
        ArrearAmount = (parseFloat(ArrearAmount) + parseFloat(item.ArrearAmount || 0)).toFixed(2);
        TotalAmount = (parseFloat(TotalAmount) + parseFloat(item.Amount)).toFixed(2);
      });
      that.selectTimeCard.forEach(function(item) {
        if (!item.IsLargess) {
          payAmount = (parseFloat(payAmount) + parseFloat(item.PayAmount)).toFixed(2);
          ArrearAmount = (parseFloat(ArrearAmount) + parseFloat(item.ArrearAmount || 0)).toFixed(2);
          TotalAmount = (parseFloat(TotalAmount) + parseFloat(item.TotalAmount)).toFixed(2);
          PricePreferentialAmount = (parseFloat(PricePreferentialAmount) + parseFloat(item.discountPrice)).toFixed(2);
          CardPreferentialAmount = (parseFloat(CardPreferentialAmount) + parseFloat(item.CardDiscountPrice)).toFixed(2);
          CardDeductionAmount = (parseFloat(CardDeductionAmount) + parseFloat(item.CardDeductionAmount)).toFixed(2);
        }
      });
      that.selectGeneralCard.forEach(function(item) {
        if (!item.IsLargess) {
          payAmount = (parseFloat(payAmount) + parseFloat(item.PayAmount)).toFixed(2);
          ArrearAmount = (parseFloat(ArrearAmount) + parseFloat(item.ArrearAmount || 0)).toFixed(2);
          TotalAmount = (parseFloat(TotalAmount) + parseFloat(item.TotalAmount)).toFixed(2);
          PricePreferentialAmount = (parseFloat(PricePreferentialAmount) + parseFloat(item.discountPrice)).toFixed(2);
          CardPreferentialAmount = (parseFloat(CardPreferentialAmount) + parseFloat(item.CardDiscountPrice)).toFixed(2);
          CardDeductionAmount = (parseFloat(CardDeductionAmount) + parseFloat(item.CardDeductionAmount)).toFixed(2);
        }
      });
      that.selectPackageCard.forEach(function(item) {
        if (!item.IsLargess) {
          payAmount = (parseFloat(payAmount) + parseFloat(item.PayAmount)).toFixed(2);
          ArrearAmount = (parseFloat(ArrearAmount) + parseFloat(item.ArrearAmount || 0)).toFixed(2);
          TotalAmount = (parseFloat(TotalAmount) + parseFloat(item.TotalAmount)).toFixed(2);
          PricePreferentialAmount = (parseFloat(PricePreferentialAmount) + parseFloat(item.discountPrice)).toFixed(2);
          CardPreferentialAmount = (parseFloat(CardPreferentialAmount) + parseFloat(item.CardDiscountPrice)).toFixed(2);
          CardDeductionAmount = (parseFloat(CardDeductionAmount) + parseFloat(item.CardDeductionAmount)).toFixed(2);
        }
      });
      that.selectProduct.forEach(function(item) {
        if (!item.IsLargess) {
          payAmount = (parseFloat(payAmount) + parseFloat(item.PayAmount)).toFixed(2);
          ArrearAmount = (parseFloat(ArrearAmount) + parseFloat(item.ArrearAmount || 0)).toFixed(2);
          TotalAmount = (parseFloat(TotalAmount) + parseFloat(item.TotalAmount)).toFixed(2);
          PricePreferentialAmount = (parseFloat(PricePreferentialAmount) + parseFloat(item.discountPrice)).toFixed(2);
          CardPreferentialAmount = (parseFloat(CardPreferentialAmount) + parseFloat(item.CardDiscountPrice)).toFixed(2);
          CardDeductionAmount = (parseFloat(CardDeductionAmount) + parseFloat(item.CardDeductionAmount)).toFixed(2);
        }
      });
      that.Amount = TotalAmount;
      that.PayAmount = (payAmount - that.cardDeductionAmount).toFixed(2);
      that.ArrearAmount = ArrearAmount;
      that.PricePreferentialAmount = PricePreferentialAmount;
      that.CardPreferentialAmount = CardPreferentialAmount;
      that.CardDeductionAmount = CardDeductionAmount;
      that.payList = [{ PayMethodID: "", Amount: "", price: 0 }];
      that.PayCashAmount = 0;
      that.payTotalPrice = that.PayAmount;
      //计算购买储值卡金额，用于结账储值卡不可抵扣储值卡
      that.savingCardPrice = 0;
      if (that.savingCardAllGoods.length) {
        that.selectSavingCard.forEach(function(item) {
          that.savingCardPrice = (parseFloat(that.savingCardPrice) + parseFloat(item.PayAmount)).toFixed(2);
        });
        that.selectPackageCard.forEach(function(item) {
          item.noLargess.forEach(function(noLargess) {
            if (noLargess.isCardType == 2) {
              that.savingCardPrice = (parseFloat(that.savingCardPrice) + parseFloat(noLargess.PayAmount)).toFixed(2);
            }
          });
        });
      }
    },
    // 结账
    billClick: function() {
      var that = this;
      // var row = that.rowModuleTo
      
      var list = that.selectTimeCard;
      var selectPackageCard = that.selectPackageCard;
      if (that.customerID == null) {
        that.$message.error("请填写会员信息");
        return;
      }

      for (let i in list) {
        if (parseInt(list[i].CustomerTargetWeight) >= parseInt(list[i].CurrentWeight)) {
          that.$message.error("目标体重不能超过当前体重");
          return;
        }
      }
      for (let j in selectPackageCard) {
        for (let s in selectPackageCard[j].noLargess) {
          if (parseInt(selectPackageCard[j].noLargess[s].CustomerTargetWeight) >= parseInt(selectPackageCard[j].noLargess[s].CurrentWeight)) {
            that.$message.error("目标体重不能超过当前体重");
            return;
          }
        }
      }
      if ((that.selectProject.length <= 0) & (that.selectSavingCard.length <= 0) & (that.selectTimeCard.length <= 0) & (that.selectGeneralCard.length <= 0) & (that.selectPackageCard.length <= 0) & (that.selectProduct.length <= 0)) {
        that.$message.error("请选择商品");
        return;
      }
      if (that.getBillDate() == null) {
        that.$message.error("请输入补录日期");
        return;
      }

      if (!that.BuyProperty) {
        that.$message.error("请选择购买属性");
        return;
      }

      let TimeCardBool = true;
      if (that.selectTimeCard.length > 0) {
        that.selectTimeCard.forEach((TimeCard) => {
          if (TimeCard.TargetWeight !== "") {
            if (TimeCard.CurrentWeight.trim() == "") {
              that.$message.error("请填写时效卡当前体重");
              TimeCardBool = false;
            } else if (TimeCard.CustomerTargetWeight.trim() == "") {
              that.$message.error("请填写时效卡目标体重");
              TimeCardBool = false;
            }
          } else {
            return true;
          }
        });
      }
      if (!TimeCardBool) return;
      let PackageCardBool = true;
      if (that.selectPackageCard.length > 0) {
        that.selectPackageCard.forEach((item) => {
          item.noLargess.forEach((ItemPack) => {
            console.log(ItemPack);
            if (ItemPack.cardType == "时效卡" && ItemPack.TargetWeight !== "") {
              if (!ItemPack.CurrentWeight) {
                that.$message.error("请填写套餐卡所属时效卡体重");
                PackageCardBool = false;
              } else if (!ItemPack.CustomerTargetWeight) {
                that.$message.error("请填写套餐卡所属时效卡体重");
                PackageCardBool = false;
              }
            } else {
              return true;
            }
          });
        });
      }
      if (!PackageCardBool) return;

      that.dialogBill = true;
      that.savingCardAllGoods = Enumerable.from(that.savingCardAllGoods)
        .select((val) => ({
          checked: false,
          AccountID: val.AccountID,
          Balance: val.Balance,
          ID: val.ID,
          LargessBalance: val.LargessBalance,
          SavingCardName: val.SavingCardName,
          TotalBalance: val.TotalBalance,
          TotalPrice: val.TotalBalance,
          Type: val.Type,
          TotalAmount: "",
          IsLargess: val.IsLargess,
        }))
        .toArray();
      that.savingDeductionPriceAll();
      that.payAmountData();
    },
    // 支付方式支付金额变化
    payPriceChange: function(item) {
      var that = this;
      var payAmount = (parseFloat(that.PayAmount) + parseFloat(item.price)).toFixed(2);
      if (parseFloat(item.Amount) > parseFloat(payAmount)) {
        item.Amount = payAmount;
      }
      item.price = item.Amount;
      that.paymentAmountData();
    },
    payMethodChange: function(item) {
      var that = this;
      if (item.PayMethodID == "") {
        item.Amount = "";
      } else {
        if (item.Amount == "") {
          item.Amount = parseFloat(that.PayAmount).toFixed(2);
        }
      }
      that.payPriceChange(item);
    },
    // 支付方式金额总计
    paymentAmountData: function() {
      var that = this;
      var amount = 0;
      that.payList.forEach(function(item) {
        amount = (parseFloat(amount) + (parseFloat(item.price) || 0)).toFixed(2);
      });
      that.PayCashAmount = amount;
      that.PayAmount = (that.payTotalPrice - amount).toFixed(2);
    },
    // 删除支付
    removePayClick: function(index) {
      var that = this;
      that.payList.splice(index, 1);
      that.paymentAmountData();
    },
    // 添加支付
    addPayclick: function() {
      var that = this;
      that.payList.push({ PayMethodID: "", Amount: "", price: 0 });
    },

    // 确认结帐
    submitPayClick: function() {
      var that = this;
      that.modalLoading = true;
      if (that.PayAmount != 0) {
        that.$message.error({
          message: "请填写收款金额。",
          duration: 2000,
        });
        that.modalLoading = false;
        return;
      }
      that.selectProject.forEach(function(item) {
        item.ProjectSaleHandler = [];
        item.projectSavingCardDeduction = [];
        item.handleTypeList.forEach(function(handler) {
          handler.Employee.forEach(function(employee) {
            item.ProjectSaleHandler.push({
              SaleHandlerID: employee.SaleHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });
        item.projectSavingCardDeduction = Enumerable.from(item.savingCardDeduction)
          .select((val) => ({
            SavingCardAccountID: val.AccountID,
            DeductionAmount: val.TotalAmount,
            PreferentialAmount: val.cardDiscountPrice,
            Type: val.Type,
            ID: val.ID,
          }))
          .toArray();
      });
      that.selectProduct.forEach(function(item) {
        item.ProductSaleHandler = [];
        item.productSavingCardDeduction = [];
        item.handleTypeList.forEach(function(handler) {
          handler.Employee.forEach(function(employee) {
            item.ProductSaleHandler.push({
              SaleHandlerID: employee.SaleHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });
        item.productSavingCardDeduction = Enumerable.from(item.savingCardDeduction)
          .select((val) => ({
            SavingCardAccountID: val.AccountID,
            DeductionAmount: val.TotalAmount,
            PreferentialAmount: val.cardDiscountPrice,
            Type: val.Type,
            ID: val.ID,
          }))
          .toArray();
      });
      that.selectGeneralCard.forEach(function(item) {
        item.GeneralCardSaleHandler = [];
        item.generalSavingCardDeduction = [];
        item.handleTypeList.forEach(function(handler) {
          handler.Employee.forEach(function(employee) {
            item.GeneralCardSaleHandler.push({
              SaleHandlerID: employee.SaleHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });
        item.generalSavingCardDeduction = Enumerable.from(item.savingCardDeduction)
          .select((val) => ({
            SavingCardAccountID: val.AccountID,
            DeductionAmount: val.TotalAmount,
            PreferentialAmount: val.cardDiscountPrice,
            Type: val.Type,
            ID: val.ID,
          }))
          .toArray();
      });
      that.selectTimeCard.forEach(function(item) {
        item.TimeCardSaleHandler = [];
        item.timeCardSavingCardDeduction = [];
        item.handleTypeList.forEach(function(handler) {
          handler.Employee.forEach(function(employee) {
            item.TimeCardSaleHandler.push({
              SaleHandlerID: employee.SaleHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });
        item.timeCardSavingCardDeduction = Enumerable.from(item.savingCardDeduction)
          .select((val) => ({
            SavingCardAccountID: val.AccountID,
            DeductionAmount: val.TotalAmount,
            PreferentialAmount: val.cardDiscountPrice,
            Type: val.Type,
            ID: val.ID,
          }))
          .toArray();
      });
      that.selectSavingCard.forEach(function(item) {
        item.SavingCardSaleHandler = [];
        item.handleTypeList.forEach(function(handler) {
          handler.Employee.forEach(function(employee) {
            item.SavingCardSaleHandler.push({
              SaleHandlerID: employee.SaleHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });
      });
      that.selectPackageCard.forEach(function(item) {
        item.PackageCardSaleHandler = [];
        item.packageSavingCardDeduction = [];
        item.packageCardGoods = {};
        item.packageCardLargessGoods = {};
        var Project = [];
        var Product = [];
        var GeneralCard = [];
        var TimeCard = [];
        var SavingCard = [];
        var ProjectLargess = [];
        var ProductLargess = [];
        var GeneralCardLargess = [];
        var TimeCardLargess = [];
        var SavingCardLargess = [];
        item.handleTypeList.forEach(function(handler) {
          handler.Employee.forEach(function(employee) {
            item.PackageCardSaleHandler.push({
              SaleHandlerID: employee.SaleHandlerID,
              EmployeeID: employee.EmployeeID,
              Scale: employee.Discount,
            });
          });
        });
        item.packageSavingCardDeduction = Enumerable.from(item.savingCardDeduction)
          .select((val) => ({
            SavingCardAccountID: val.AccountID,
            DeductionAmount: val.TotalAmount,
            PreferentialAmount: val.cardDiscountPrice,
            Type: val.Type,
            ID: val.ID,
          }))
          .toArray();
        Project = Enumerable.from(item.noLargess)
          .where(function(i) {
            return i.isCardType == 1;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            PackagePrice: val.Price,
            PackageTotalPrice: val.TotalPrice,
            PackageQuantity: val.number,
            ArrearAmount: val.ArrearAmount == "" ? 0 : val.ArrearAmount,
            TotalAmount: val.totalAmount,
            PayAmount: val.PayAmount,
            PricePreferentialAmount: val.discountPrice,
            CardPreferentialAmount: val.cardDiscountPrice,
            Remark: val.Remark,
          }))
          .toArray();
        Product = Enumerable.from(item.noLargess)
          .where(function(i) {
            return i.isCardType == 6;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            PackagePrice: val.Price,
            PackageTotalPrice: val.TotalPrice,
            PackageQuantity: val.number,
            ArrearAmount: val.ArrearAmount == "" ? 0 : val.ArrearAmount,
            TotalAmount: val.totalAmount,
            PayAmount: val.PayAmount,
            PricePreferentialAmount: val.discountPrice,
            CardPreferentialAmount: val.cardDiscountPrice,
            Remark: val.Remark,
          }))
          .toArray();
        GeneralCard = Enumerable.from(item.noLargess)
          .where(function(i) {
            return i.isCardType == 4;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            PackagePrice: val.Price,
            PackageTotalPrice: val.TotalPrice,
            PackageQuantity: val.number,
            ArrearAmount: val.ArrearAmount == "" ? 0 : val.ArrearAmount,
            TotalAmount: val.totalAmount,
            PayAmount: val.PayAmount,
            PricePreferentialAmount: val.discountPrice,
            CardPreferentialAmount: val.cardDiscountPrice,
            Remark: val.Remark,
          }))
          .toArray();
        TimeCard = Enumerable.from(item.noLargess)
          .where(function(i) {
            return i.isCardType == 3;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            PackagePrice: val.Price,
            PackageTotalPrice: val.TotalPrice,
            PackageQuantity: val.number,
            ArrearAmount: val.ArrearAmount == "" ? 0 : val.ArrearAmount,
            TotalAmount: val.totalAmount,
            PayAmount: val.PayAmount,
            PricePreferentialAmount: val.discountPrice,
            CardPreferentialAmount: val.cardDiscountPrice,
            CurrentWeight: val.CurrentWeight || "",
            CustomerTargetWeight: val.CustomerTargetWeight || "",
            TargetWeight: val.TargetWeight,
            Remark: val.Remark,
          }))
          .toArray();
        SavingCard = Enumerable.from(item.noLargess)
          .where(function(i) {
            return i.isCardType == 2;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            Amount: val.Price,
            TotalPrice: val.TotalPrice,
            ArrearAmount: val.ArrearAmount == "" ? 0 : val.ArrearAmount,
            PackageQuantity: val.number,
            TotalAmount: val.totalAmount,
            Remark: val.Remark,
          }))
          .toArray();

        ProjectLargess = Enumerable.from(item.largess)
          .where(function(i) {
            return i.isCardType == 1;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            PackagePrice: val.Price,
            PackageTotalPrice: val.TotalPrice,
            PackageQuantity: val.number,
            Remark: val.Remark,
          }))
          .toArray();
        ProductLargess = Enumerable.from(item.largess)
          .where(function(i) {
            return i.isCardType == 6;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            PackagePrice: val.Price,
            PackageTotalPrice: val.TotalPrice,
            PackageQuantity: val.number,
            Remark: val.Remark,
          }))
          .toArray();
        GeneralCardLargess = Enumerable.from(item.largess)
          .where(function(i) {
            return i.isCardType == 4;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            PackagePrice: val.Price,
            PackageTotalPrice: val.TotalPrice,
            PackageQuantity: val.number,
            Remark: val.Remark,
          }))
          .toArray();
        TimeCardLargess = Enumerable.from(item.largess)
          .where(function(i) {
            return i.isCardType == 3;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            PackagePrice: val.Price,
            PackageTotalPrice: val.TotalPrice,
            PackageQuantity: val.number,
            Remark: val.Remark,
          }))
          .toArray();
        SavingCardLargess = Enumerable.from(item.largess)
          .where(function(i) {
            return i.isCardType == 2;
          })
          .select((val) => ({
            ID: val.ID,
            Quantity: val.Amount,
            // 原数据
            // PackagePrice: val.Price,
            // PackageTotalPrice: val.TotalPrice,
            // 修改后数据
            PackagePrice: val.TotalPrice,
            PackageTotalPrice: val.Price,
            PackageQuantity: val.number,
            Remark: val.Remark,
          }))
          .toArray();
        item.packageCardGoods.Project = Project;
        item.packageCardGoods.Product = Product;
        item.packageCardGoods.GeneralCard = GeneralCard;
        item.packageCardGoods.TimeCard = TimeCard;
        item.packageCardGoods.SavingCard = SavingCard;
        item.packageCardLargessGoods.Project = ProjectLargess;
        item.packageCardLargessGoods.Product = ProductLargess;
        item.packageCardLargessGoods.GeneralCard = GeneralCardLargess;
        item.packageCardLargessGoods.TimeCard = TimeCardLargess;
        item.packageCardLargessGoods.SavingCard = SavingCardLargess;
      });
      var Project = Enumerable.from(that.selectProject)
        .select((val) => ({
          ProjectID: val.ID,
          Quantity: val.number,
          Price: val.Price,
          TotalAmount: val.TotalAmount,
          PayAmount: val.PayAmount,
          ArrearAmount: val.ArrearAmount,
          SavingCardDeductionAmount: val.CardDeductionAmount,
          PricePreferentialAmount: val.discountPrice,
          CardPreferentialAmount: val.CardDiscountPrice,
          IsLargess: val.IsLargess,
          ProjectSaleHandler: val.ProjectSaleHandler,
          SavingCardDeduction: val.projectSavingCardDeduction,
          Remark: val.Remark,
        }))
        .toArray();
      var Product = Enumerable.from(that.selectProduct)
        .select((val) => ({
          ProductID: val.ID,
          Quantity: val.number,
          Price: val.Price,
          TotalAmount: val.TotalAmount,
          PayAmount: val.PayAmount,
          ArrearAmount: val.ArrearAmount,
          SavingCardDeductionAmount: val.CardDeductionAmount,
          PricePreferentialAmount: val.discountPrice,
          CardPreferentialAmount: val.CardDiscountPrice,
          IsLargess: val.IsLargess,
          ProductSaleHandler: val.ProductSaleHandler,
          SavingCardDeduction: val.productSavingCardDeduction,
          Remark: val.Remark,
        }))
        .toArray();
      var GeneralCard = Enumerable.from(that.selectGeneralCard)
        .select((val) => ({
          GeneralCardID: val.ID,
          Quantity: val.number,
          Price: val.Price,
          TotalAmount: val.TotalAmount,
          PayAmount: val.PayAmount,
          ArrearAmount: val.ArrearAmount,
          SavingCardDeductionAmount: val.CardDeductionAmount,
          PricePreferentialAmount: val.discountPrice,
          CardPreferentialAmount: val.CardDiscountPrice,
          IsLargess: val.IsLargess,
          GeneralCardSaleHandler: val.GeneralCardSaleHandler,
          SavingCardDeduction: val.generalSavingCardDeduction,
          Remark: val.Remark,
        }))
        .toArray();
      var TimeCard = Enumerable.from(that.selectTimeCard)
        .select((val) => ({
          TimeCardID: val.ID,
          Quantity: val.number,
          Price: val.Price,
          CurrentWeight: val.CurrentWeight,
          CustomerTargetWeight: val.CustomerTargetWeight,
          TargetWeight: val.TargetWeight,
          TotalAmount: val.TotalAmount,
          PayAmount: val.PayAmount,
          ArrearAmount: val.ArrearAmount,
          SavingCardDeductionAmount: val.CardDeductionAmount,
          PricePreferentialAmount: val.discountPrice,
          CardPreferentialAmount: val.CardDiscountPrice,
          IsLargess: val.IsLargess,
          TimeCardSaleHandler: val.TimeCardSaleHandler,
          SavingCardDeduction: val.timeCardSavingCardDeduction,
          Remark: val.Remark,
        }))
        .toArray();
      var SavingCard = Enumerable.from(that.selectSavingCard)
        .select((val) => ({
          SavingCardID: val.ID,
          Quantity: val.number,
          Price: val.Price,
          TotalAmount: val.Amount,
          PayAmount: val.PayAmount,
          ArrearAmount: val.ArrearAmount,
          LargessAmount: val.LargessPrice,
          LargessPrice: val.largessPrice,
          SavingCardSaleHandler: val.SavingCardSaleHandler,
          Remark: val.Remark,
        }))
        .toArray();
      var PackageCard = Enumerable.from(that.selectPackageCard)
        .select((val) => ({
          PackageCardID: val.ID,
          Quantity: val.number,
          Price: val.Price,
          TotalAmount: val.TotalAmount,
          PayAmount: val.PayAmount,
          ArrearAmount: val.ArrearAmount,
          CurrentWeight: val.CurrentWeight || "",
          CustomerTargetWeight: val.CustomerTargetWeight || "",
          TargetWeight: val.TargetWeight,
          SavingCardDeductionAmount: val.CardDeductionAmount,
          PricePreferentialAmount: val.discountPrice,
          CardPreferentialAmount: val.CardDiscountPrice,
          IsLargess: val.IsLargess,
          PackageCardSaleHandler: val.PackageCardSaleHandler,
          SavingCardDeduction: val.packageSavingCardDeduction,
          PackageCardGoods: val.packageCardGoods,
          PackageCardLargessGoods: val.packageCardLargessGoods,
          Remark: val.Remark,
        }))
        .toArray();
      var payList = Enumerable.from(that.payList)
        .where(function(i) {
          return i.PayMethodID != "" && i.Amount != "";
        })
        .select((val) => ({
          PayMethodID: val.PayMethodID,
          Amount: val.Amount,
        }))
        .toArray();

      that.SavingCardDeduction = Enumerable.from(that.savingCardAllGoods)
        .where(function(i) {
          return i.checked && parseFloat(i.TotalAmount) != 0;
        })
        .select((val) => ({
          Type: val.Type,
          SavingCardAccountID: val.AccountID,
          DeductionAmount: val.TotalAmount,
          ID: val.ID,
        }))
        .toArray();
      var CardDeductionAmount = (parseFloat(that.CardDeductionAmount) + parseFloat(that.cardDeductionAmount)).toFixed(2);
      var params = {
        BillID: that.BillID,
        ReturnedCommissionType: that.ReturnedCommissionType,
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        Amount: that.Amount,
        PayAmount: that.PayCashAmount,
        ArrearAmount: that.ArrearAmount,
        CardDeductionAmount: CardDeductionAmount,
        PricePreferentialAmount: that.PricePreferentialAmount,
        CardPreferentialAmount: that.CardPreferentialAmount,
        Remark: that.Remark,
        Project: Project,
        Product: Product,
        GeneralCard: GeneralCard,
        TimeCard: TimeCard,
        SavingCard: SavingCard,
        PackageCard: PackageCard,
        PayMethod: payList,
        SavingCardDeduction: that.SavingCardDeduction,
        BuyProperty:that.BuyProperty,
      };
      API.createSaleBill(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.selectProject = [];
            that.selectProduct = [];
            that.selectGeneralCard = [];
            that.selectTimeCard = [];
            that.selectSavingCard = [];
            that.selectPackageCard = [];
            that.$parent.IsSupplement = false;
            that.$parent.BillDate = null;
            that.orderAmount = that.Amount;
            that.Amount = 0; //订单金额
            that.PayAmount = 0; //现金支付金额
            that.payTotalPrice = 0; //待支付总金额
            that.ArrearAmount = 0; //欠款金额
            that.cardDeductionAmount = 0;
            that.CardDeductionAmount = 0; // 储值卡抵扣金额
            that.PricePreferentialAmount = 0; //手动改价优惠金额
            that.CardPreferentialAmount = 0; //卡优惠金额
            that.Remark = "";
            that.savingCardAllGoodsData();
            that.savingCardSomeGoodsData();
            that.orderNumber = res.Message;
            that.dialogBill = false;
            that.dialogPay = true;
            // that.$message.success({ message: "收款完成", duration: 3000 });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    },
    // 套餐明细
    packDetailClick: function(row) {
      row.isPackDetail = !row.isPackDetail;
    },
    /** 阶梯价格选中项 样式   */
    RechargeRulesClass(item, index) {
      // let mar = index == 0?"":""
      let style = item.RechargeRulesSelectIndex == index ? "selectRules" : "";
      return " " + style;
    },
    /**  选择 阶梯价格规则  */
    selectRechargeRules(item, RechargeItem, index) {
      let that = this;
      item.RechargeRulesSelectIndex = index;

      item.number = 1;
      item.Price = RechargeItem.Price;
      item.LargessPrice = RechargeItem.LargessPrice;
      item.largessPrice = RechargeItem.LargessPrice;
      item.Amount = RechargeItem.Price;
      item.totalPrice = RechargeItem.Price;
      item.PayAmount = RechargeItem.Price;
    },
    /**  打印小票  */
    printOrderReceipt() {
      var that = this;
      that.getOrderDetail();
      that.getReceiptConfig();
    },
    /** 获取订单详情 */
    getOrderDetail() {
      var that = this;
      that.printLoading = true;

      var params = {
        SaleBillID: that.orderNumber,
      };

      orderAPI
        .getOrderDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.saleOrderDetail = res.Data;
            that.cashierReceiptDialogVisible = true;
          }
        })
        .finally(function() {
          that.printLoading = false;
        });
    },
    /**  获取打印内容  */
    getprintSaleBillContent() {
      let that = this;
      let params = {
        SaleBillID: that.saleOrderDetail.ID,
      };
      cashierAPI
        .printSaleBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (let index = 0; index < that.cashierReceipt.PrintQuantity; index++) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    /**  获取小票配置信息  */
    getReceiptConfig() {
      var that = this;
      cashierAPI
        .getReceiptConfigBill()
        .then((res) => {
          if (res.StateCode == 200) {
            that.cashierReceipt = res.Data;
          }
        })
        .finally(() => {});
    },

    // 姓名隐藏
    formatName(name) {
      return printReceipt.hiddenName(name);
    },
    // 手机号隐藏
    formatPhone(phone) {
      return printReceipt.hiddenPhone(phone);
    },
    /**  结账成功关闭弹窗  */
    closeSucceedDialog() {
      this.Remark = "";
    },
  },
  mounted() {
    var that = this;
    that.customerID = that.CustomerID;
    that.IsReturnedCommissionToo = this.$route.params.IsReturnedCommission;
    socket = printReceipt.getSocket((res) => {
      if (res.status == "success") {
        that.$message.success({
          message: "打印成功",
          duration: 2000,
        });
        that.cashierReceiptDialogVisible = false;
      }
    });

    if (localStorage.getItem("access-user")) {
      that.userName = JSON.parse(localStorage.getItem("access-user")).EmployeeName;
      that.entityName = JSON.parse(localStorage.getItem("access-user")).EntityName;
    }

    that.projectData();
    that.productData();
    that.generalCardData();
    that.timeCardData();
    that.savingCardData();
    that.packageCardData();
    that.goodsDataInit();
    that.projectHandlerData();
    that.productHandlerData();
    that.generalHandlerData();
    that.timeCardHandlerData();
    that.savingCardHandlerData();
    that.packageCardHandlerData();
    that.salePayMethodData();
    if (that.customerID != null) {
      that.savingCardAllGoodsData();
      that.savingCardSomeGoodsData();
    }
  },
};
</script>

<style lang="scss">
.sale_content {
  font-size: 13px;
  height: 100%;
  .project_left {
    border-right: 1px solid #eee;
    height: 100%;
    color: #303133;
    .el-tabs__content {
      div {
        line-height: 23px;
      }
    }
    .el-tabs {
      height: calc(100% - 62px);
      .el-tabs__header {
        margin: 0;
        .el-tabs__nav-scroll {
          // padding-left: 15px;
          margin-left: 15px;
        }
      }
      .el-tabs__content {
        height: calc(100% - 40px);
        .el-tab-pane {
          height: 100%;
          .category_project {
            height: 100%;
            .category {
              height: 100%;
              .category_select {
                color: #039be5;
              }
            }
            .project {
              height: 100%;
              overflow: auto;
              .el-collapse {
                .el-collapse-item__header {
                  background-color: #f5f7fa !important;
                  padding: 0 10px;
                }
                .el-collapse-item__content {
                  padding: 0;
                }
              }
              .category_sub_list {
                overflow-x: auto;
                .category_sub_select {
                  color: #039be5;
                }
              }
              .project_list {
                /*height: calc(100% - 57px);*/
                height: 100%;
              }
              .producct_list {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .project_right {
    height: 100%;
    .el-main {
      padding: 0;
      .row_header {
        background-color: #ecf8ff;
        padding: 10px;
      }
      .employee_num {
        width: 90px;
        .el-input-group__append {
          padding: 0 10px;
        }
      }
      .el-form-item__label {
        font-size: 13px !important;
      }
    }
    .el-footer {
      height: initial !important;
      padding: 10px;
    }
  }
}

.el-icon-sort {
  color: #666;
  font-size: 14px;
  transform: rotate(90deg);
}

.dialog_bill_detail {
  background-color: #ecf8ff;
  padding: 15px;
  border-radius: 5px;
}

.el-scrollbar_height {
  height: 100%;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.el-scrollbar_x_width {
  .el-scrollbar__wrap {
    .el-scrollbar__view {
      white-space: nowrap;
      .el-menu-item {
        display: inline-block;
      }
      .is-active {
        a {
          color: #409eff;
        }
      }
    }
  }
}

.el-input__inner {
  padding: 0 0 0 10px;
}

.saving_discount {
  .el-table__row {
    background-color: #f5f7fa;
  }
}

.savingCardAmount {
  .el-input__inner {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.savingCardLargessAmount {
  .el-input__inner {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
.el-divider {
  margin-top: 15px;
  margin-bottom: 15px;
}
.sell-el-divider {
  margin-top: 5px;
  margin-bottom: 5px;
}

.selectRules {
  border: 1px solid #50bfff;
  box-shadow: 0 2px 6px 0 #50bfff !important;
}
</style>
